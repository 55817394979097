import { TableRow, TableCell } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import { formatDateTime, formatDrillScoreReport } from "../users/helpers";
import JsonView from "react18-json-view";
import "react18-json-view/src/style.css";

export default function DrillsIdCsvExpandRow({ data, name, isAnalysis, isScores }) {
  const [open, setOpen] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [collapsed, setCollapse] = useState(false);

  const content_string = data?.analysis_content_string;
  const jsonRes = JSON.stringify(data, null, 2);

  function getJsonToDisplay() {
    const obj = { ...data };
    delete obj.analysis_content_string;
    return obj;
  }

  function downloadFile(file, additionalName) {
    const blob = new Blob([file], { type: "text/plain" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${name}${additionalName || ""}.txt`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function safeStringify(obj) {
    try {
      return JSON.stringify(obj, null, 2);
    } catch (error) {
      console.error("JSON error", error);
      return "{}";
    }
  }

  return (
    <>
      <TableRow className={`drillsCsv__expand ${data && Object.keys(data).length === 0 ? "disabled" : ""}`}>
        <TableCell>{name}</TableCell>
        <TableCell>{formatDateTime(data?.generated_at)}</TableCell>
        <TableCell>
          <button
            onClick={() => downloadFile(jsonRes)}
            className="drillsCsv__downloadBtn">
            <DownloadIcon />
          </button>
        </TableCell>
        <TableCell>
          <button
            onClick={() => {
              setOpen(!open);
            }}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </button>
        </TableCell>
      </TableRow>

      <TableRow className="expand">
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={4}>
          <Collapse
            in={open}
            timeout={0}
            unmountOnExit
            className="drillsCsv__collapse">
            <div className="userNav__row userNav">
              <button
                onClick={() => setShowContent(false)}
                className={`${!showContent ? "active" : ""}`}>
                JSON
              </button>
              {(isAnalysis || isScores) && (
                <button
                  onClick={() => setShowContent(true)}
                  className={`${showContent ? "active" : ""}`}>
                  Content
                </button>
              )}
            </div>

            {!showContent && (
              <div className="prompts__log">
                <div className="drillsCsv__collapse-btns">
                  <button
                    onClick={() => downloadFile(safeStringify(getJsonToDisplay(), null, 2), "-json")}
                    className="drillsCsv__collapse-download">
                    Download
                  </button>
                  <button
                    onClick={() => setCollapse(collapsed ? false : 1)}
                    className="drillsCsv__collapse-download">
                    {collapsed ? "Detailed" : "Collapsed"} list
                  </button>
                </div>
                <JsonView
                  src={getJsonToDisplay()}
                  collapsed={collapsed}
                />
              </div>
            )}
            {showContent && (isAnalysis || isScores) && (
              <div className="prompts__log">
                <button
                  onClick={() => downloadFile(isAnalysis ? content_string : formatDrillScoreReport(data), "-content")}
                  className="drillsCsv__collapse-download">
                  Download
                </button>
                <pre className="drillsCsv__log">{isAnalysis ? content_string : formatDrillScoreReport(data)}</pre>
              </div>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
