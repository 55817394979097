import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
  loading: true,
  isInitialized: false,
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setAdminData: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.isInitialized = true;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setAdminData, setLoading } = adminSlice.actions;
export default adminSlice.reducer;
