import { useForm, Controller } from "react-hook-form";
import { useState, useCallback, useEffect } from "react";
import { DialogActions, DialogContent } from "@mui/material";
import { Button, SelectPicker } from "rsuite";
import AuthFormGroup from "../auth/AuthFormGroup";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../store/snackbarSlice";
import { instance } from "../../routes/axios";

export default function SetDefualtForm({ close, fetchData }) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [modelDetectionTypes, setModelDetectionTypes] = useState([]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { model_detection_version: "" },
    mode: "onChange",
  });

  const fetchVersions = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await instance.post("/fetch-all-yolo-versions", {});
      if (res.data?.data?.versions) {
        const formattedData = res.data?.data?.versions.map((versionObj) => ({
          label: `Model Version ${versionObj?.version} (${versionObj?.actualVersionValue})  ${versionObj?.isDefault ? "Default" : ""}`,
          value: versionObj._id,
        }));
        setModelDetectionTypes(formattedData);
      } else {
        setModelDetectionTypes([]);
      }
    } catch (err) {
      dispatch(showSnackbar({ message: "Something went wrong", severity: "error" }));
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchVersions();
  }, [fetchVersions]);

  async function onSubmit(data) {
    setIsLoading(true);
    try {
      await instance.put(`/set-default-yolo-version/${data.model_detection_version}`);
      await fetchData();
      close();
      dispatch(
        showSnackbar({
          message: "Default Version has been successfully updated",
          severity: "success",
        })
      );
    } catch (err) {
      dispatch(
        showSnackbar({
          message: err?.response?.data?.message || "Something went wrong",
          severity: "error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  }

  //todo:kris .form class for input labels check everywhere
  return (
    <>
      <DialogContent
        dividers
        className="c-dialog__content">
        <div
          className="form prompts__form"
          id="scroll-dialog-description">
          <div className="form__fields">
            <AuthFormGroup
              label="Model Detection Version"
              error={errors.model_detection_version}>
              <Controller
                name="model_detection_version"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <SelectPicker
                    size="lg"
                    id={field?.name}
                    value={field?.value}
                    data={modelDetectionTypes}
                    searchable={false}
                    onChange={field.onChange}
                    placeholder="Select Model Detection Version"
                  />
                )}
              />
            </AuthFormGroup>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          appearance="ghost"
          onClick={close}
          className="outlined-btn">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
          appearance="primary"
          className="outlined-btn black-btn min-width">
          Set Defualt
        </Button>
      </DialogActions>
    </>
  );
}
