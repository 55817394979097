import { Buffer } from "buffer";
import process from "process";
import React from "react";
import ReactDOM from "react-dom/client";
import "rsuite/dist/rsuite-no-reset.min.css";
import "./assets/styles/tailwind.css";
import "./assets/styles/global.scss";
import App from "./App";

// Polyfill setup
window.global = window;
window.Buffer = Buffer;
window.process = process;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
