import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginPage from "../pages/LoginPage";
import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import DashboardPage from "../pages/DashboardPage";
import UsersPage from "../pages/UsersPage";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import UserIdPage from "../pages/UserIdPage";
import DrillHistoryPage from "../pages/DrillHistoryPage";
import ActivityPage from "../pages/ActivityPage";
import HighlightsPage from "../pages/HighlightsPage";
import SessionsPage from "../pages/SessionsPage";
import CoachChatsPage from "../pages/CoachChatsPage";
import SettingsPage from "../pages/SettingsPage";
import EditProfilePage from "../pages/EditProfilePage";
import AdminsPage from "../pages/AdminsPage";
import DrillHistoryIdPage from "../pages/DrillHistoryIdPage";
import DrillHistoryIdCsvPage from "../pages/DrillHistoryIdCsvPage";
import ManageVideosPage from "../pages/ManageVideosPage";
import ConfigurationsPage from "../pages/ConfigurationsPage";
import ConfigurationsPromptPage from "../pages/ConfigurationsPromptPage";
import ScoringMetricsPage from "../pages/ScoringMetricsPage";
import ModelDetectionPage from "../pages/ModelDetectionPage";
import DrillHistoryIdHistoryLogsPage from "../pages/DrillHistoryIdHistoryLogsPage";
import AnalysisVersionsPage from "../pages/AnalysisVersionsPage";

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PublicRoute
            component={LoginPage}
            restricted
          />
        }
      />
      <Route
        path="/forgot-password"
        element={<PublicRoute component={ForgotPasswordPage} />}
      />
      <Route
        exact
        path="/reset-password/:token"
        element={<PublicRoute component={ResetPasswordPage} />}
      />
      {/* 
      <Route
        exact
        path="/admin-forgot-password/:token"
        element={<PublicRoute component={ResetPassword} />}
      /> */}
      {/* <Route
        path="*"
        element={<NotFound />}
      /> */}

      {/* Protected routes */}
      <Route
        path="/dashboard"
        element={<ProtectedRoute component={DashboardPage} />}
      />
      <Route
        path="/users"
        element={<ProtectedRoute component={UsersPage} />}
      />
      <Route
        path="/users/:id"
        element={<ProtectedRoute component={UserIdPage} />}
      />
      <Route
        path="/users/:id/drill-history"
        element={<ProtectedRoute component={DrillHistoryPage} />}
      />
      <Route
        path="/users/:id/drill-history/:drillId"
        element={<ProtectedRoute component={DrillHistoryIdPage} />}
      />
      <Route
        path="/users/:id/drill-history/:drillId/files"
        element={<ProtectedRoute component={DrillHistoryIdCsvPage} />}
      />
      <Route
        path="/users/:id/drill-history/:drillId/history-logs"
        element={<ProtectedRoute component={DrillHistoryIdHistoryLogsPage} />}
      />
      <Route
        path="/users/:id/activity"
        element={<ProtectedRoute component={ActivityPage} />}
      />
      <Route
        path="/users/:id/highlights"
        element={<ProtectedRoute component={HighlightsPage} />}
      />
      <Route
        path="/users/:id/sessions"
        element={<ProtectedRoute component={SessionsPage} />}
      />
      <Route
        path="/users/:id/coach-chats"
        element={<ProtectedRoute component={CoachChatsPage} />}
      />
      <Route
        path="/settings"
        element={<ProtectedRoute component={SettingsPage} />}
      />
      <Route
        path="/settings/edit-profile"
        element={<ProtectedRoute component={EditProfilePage} />}
      />
      <Route
        path="/admins"
        element={<ProtectedRoute component={AdminsPage} />}
      />
      <Route
        path="/manage-videos"
        element={<ProtectedRoute component={ManageVideosPage} />}
      />
      <Route
        path="/configurations"
        element={<ProtectedRoute component={ConfigurationsPage} />}
      />
      <Route
        path="/configurations/prompt-management"
        element={<ProtectedRoute component={ConfigurationsPromptPage} />}
      />
      <Route
        path="/configurations/scoring-metrics"
        element={<ProtectedRoute component={ScoringMetricsPage} />}
      />
      <Route
        path="/configurations/model-detection"
        element={<ProtectedRoute component={ModelDetectionPage} />}
      />
      <Route
        path="/configurations/analysis-versions"
        element={<ProtectedRoute component={AnalysisVersionsPage} />}
      />
    </Routes>
  );
};

export default AppRoutes;
