import { Input, Button, Tag, InputNumber } from "rsuite";
import { useForm, Controller, useController } from "react-hook-form";
import AuthFormGroup from "../auth/AuthFormGroup";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect } from "react";
import { instance } from "../../routes/axios";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../store/snackbarSlice";
import { DialogActions, DialogContent } from "@mui/material";

const scoringKeys = {
  Dribbling: ["Angle of Ball Return", "Ball Distance from Foot", "No of Touches", "Speed to Complete"],
  "First Touch": ["Dribbling 1st Touch Angle Difference", "Dribbling 1st Touch Distance", "Passing 1st Touch Distance"],
  Passing: ["Angle of Ball Return", "Average Touches", "Ball Control Distance", "Ball Speed Return", "Passing Duration"],
};

export default function MetricsUploadForm({ close, fetchData, isEdit, _id, editValues }) {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [valuesErrors, setValuesErrors] = useState([]);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    setFocus,
    formState: { errors },
  } = useForm({
    defaultValues: editValues
      ? editValues
      : {
          gameType: "",
          fileName: "",
          name: "",
          rules: {
            Dribbling: {},
            "First Touch": {},
            Passing: {},
          },
        },
  });

  async function onSubmit(data) {
    if (valuesErrors.length > 0) return;
    const formattedData = { ...data };
    if (formattedData.rules?.Dribbling?.Scoring?.["No of Touches"]) {
      formattedData.rules.Dribbling.Scoring["No. of Touches"] = formattedData.rules.Dribbling.Scoring["No of Touches"];
      delete formattedData.rules.Dribbling.Scoring["No of Touches"];
    }

    if (!isEdit) {
      delete formattedData._id;
      delete formattedData.rules?._id;
    }

    setLoading(true);
    try {
      const res = isEdit ? await instance.put(`/scoring-metrics/${_id}`, formattedData) : await instance.post("/scoring-metrics", formattedData);
      fetchData();
      close();
      dispatch(showSnackbar({ message: `Scoring metric has been successfully ${isEdit ? "Updated" : "Created" }`, severity: "success" }));
    } catch (err) {
      dispatch(showSnackbar({ message: err.response.data.message || "Something went wrong", severity: "error" }));
    } finally {
      setLoading(false);
    }
  }

  function onKeyDown(e, ruleName, keyValue) {
    const formKey = `rules.${ruleName}.Scoring[${keyValue}].Values`;
    if (e.key === "Enter") {
      const pair = e.target.value.trim().split("/").map(Number);
      if (pair.some((value) => isNaN(value) || Number(value) < 0)) {
        setValuesErrors((prev) => (prev.includes(formKey) ? prev : [...prev, formKey]));
        return;
      } else {
        setValuesErrors((prev) => prev.filter((key) => key !== formKey));
      }

      if (pair.length === 2 && !pair.some(isNaN)) {
        const currentValues = getValues(formKey);
        if (currentValues) {
          setValue(formKey, [...currentValues, pair]);
        } else {
          setValue(formKey, [pair]);
        }
        e.target.value = "";
      }
    }
  }

  return (
    <>
      <DialogContent
        dividers
        className="c-dialog__content">
        <div
          className="form scoring__form prompts__form"
          id="scroll-dialog-description">
          <div className="form__fields">
            <AuthFormGroup
              label="Game Type"
              error={errors.gameType}>
              <Controller
                name="gameType"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Input
                    value={field.value || ""}
                    onChange={field.onChange}
                  />
                )}
              />
            </AuthFormGroup>
            <AuthFormGroup
              label="File Name"
              error={errors.fileName}>
              <Controller
                name="fileName"
                rules={{
                  required: true,
                }}
                control={control}
                render={({ field }) => (
                  <Input
                    value={field.value || ""}
                    onChange={field.onChange}
                  />
                )}
              />
            </AuthFormGroup>
            <AuthFormGroup
              label="Name"
              error={errors.name}>
              <Controller
                name="name"
                rules={{
                  required: true,
                }}
                control={control}
                render={({ field }) => (
                  <Input
                    value={field.value || ""}
                    onChange={field.onChange}
                  />
                )}
              />
            </AuthFormGroup>
            {Object.keys(scoringKeys).map((ruleName) => (
              <div
                key={ruleName}
                className="metricsRule">
                <p className="metricsRule__title">{ruleName}</p>
                <div className="form__fields">
                  <AuthFormGroup label="Description">
                    <Controller
                      name={`rules.${ruleName}.Description`}
                      control={control}
                      render={({ field }) => (
                        <Input
                          as="textarea"
                          rows={2}
                          value={field.value || ""}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </AuthFormGroup>
                  <AuthFormGroup
                    label="Overall Weighting"
                    error={errors?.rules?.[ruleName]?.["Overall Weighting"]}>
                    <Controller
                      name={`rules.${ruleName}.Overall Weighting`}
                      rules={{
                        validate: (value) => {
                          if (value < 0) {
                            return "* Value should be greater or equal to 0";
                          }
                        },
                      }}
                      control={control}
                      render={({ field }) => (
                        <InputNumber
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </AuthFormGroup>
                  <div>
                    <p className="auth__form-label">Scoring</p>
                    {scoringKeys[ruleName].map((key) => (
                      <div
                        className="scoringItem"
                        key={key}>
                        <p className="scoringItem__title">{key}</p>
                        <div className="scoringItem__fields form__fields">
                          <AuthFormGroup label="Description">
                            <Controller
                              name={`rules.${ruleName}.Scoring[${key}].Description`}
                              control={control}
                              render={({ field }) => (
                                <Input
                                  as="textarea"
                                  rows={2}
                                  value={field.value || ""}
                                  onChange={field.onChange}
                                />
                              )}
                            />
                          </AuthFormGroup>
                          <AuthFormGroup
                            label="Weighting"
                            error={errors?.rules?.[ruleName]?.["Scoring"]?.[key]?.["Weighting"]}>
                            <Controller
                              name={`rules.${ruleName}.Scoring[${key}].Weighting`}
                              control={control}
                              rules={{
                                validate: (value) => {
                                  if (value < 0) {
                                    return "* Value should be greater or equal to 0";
                                  }
                                },
                              }}
                              render={({ field }) => (
                                <InputNumber
                                  value={field.value}
                                  onChange={field.onChange}
                                />
                              )}
                            />
                          </AuthFormGroup>
                          <AuthFormGroup
                            label="Values (Enter pairs of numbers ex. key / value, 1 / 2, 4.95 / 4)"
                            error={(() => {
                              const formKey = `rules.${ruleName}.Scoring[${key}].Values`;
                              if (valuesErrors.includes(formKey)) {
                                return { type: "validate", message: "* Value should be greater or equal to 0" };
                              }
                            })()}>
                            <Input
                              onKeyDown={(e) => onKeyDown(e, ruleName, key)}
                              onChange={(e) => {
                                if (!e) {
                                  const formKey = `rules.${ruleName}.Scoring[${key}].Values`;
                                  setValuesErrors((prev) => prev.filter((key) => key !== formKey));
                                }
                              }}
                            />
                          </AuthFormGroup>

                          <div>
                            {watch(`rules.${ruleName}.Scoring[${key}].Values`)?.map((pair, index) => (
                              <Tag
                                style={{ background: "white", margin: "5px" }}
                                key={index}
                                onClick={() => {
                                  const updatedValues = getValues(`rules.${ruleName}.Scoring[${key}].Values`).filter((_, i) => i !== index);
                                  setValue(`rules.${ruleName}.Scoring[${key}].Values`, updatedValues);
                                }}>
                                [{pair[0]}, {pair[1]}]
                                <CloseIcon />
                              </Tag>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit(onSubmit)}
          appearance="primary"
          className="outlined-btn black-btn min-width">
          Submit
        </Button>
      </DialogActions>
    </>
  );
}
