import { Toolbar } from "@mui/material";
import { instance } from "../../routes/axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../store/snackbarSlice";
import CustomDialogIcon from "../libs/CustomDialogIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CustomDialog from "../libs/CustomDialog";
import { Button } from "rsuite";
import ModelDetectionForm from "./ModelDetectionForm";
import SetDefaultForm from "./SetDefaultForm";

export default function ModelDetectionToolbar({ selected, fetchData, setSelected, totalCount }) {
  const [isLoading, setLoading] = useState(false);
  const [dialog, setDialog] = useState(false);
  const dispatch = useDispatch();
  const [openUpload, setOpenUpload] = useState(false);
  const [isDefault, setIsDefault] = useState(false);

  const dialogContent = {
    title: "Delete Model Detection",
    icon: <DeleteIcon />,
    text: "Are you sure you want to delete the Model Detection?",
    buttonText: "Delete",
    buttonAction: deleteModel,
  };

  async function deleteModel() {
    setLoading(true);
    try {
      for (const item of selected) {
        await instance.delete(`/delete-yolo-version/${item._id}`);
      }
      dispatch(showSnackbar({ message: `Detection Version has been deleted`, severity: "success" }));
      setSelected([]);
      fetchData();
      setDialog(false);
    } catch (err) {
      dispatch(showSnackbar({ message: "Something went wrong", severity: "error" }));
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Toolbar className="promptsTableToolbar">
        <p className="promptsTableToolbar__title">{selected.length > 0 ? `${selected.length} selected` : `${totalCount} total`}</p>
        <div className="usersTableToolbar__btns">
          <Button
            appearance="ghost"
            disabled={selected.length < 1}
            onClick={() => setDialog(true)}
            loading={isLoading}
            className="outlined-btn red-outlined-btn">
            Delete
          </Button>
          <Button
            appearance="ghost"
            onClick={() => setIsDefault(true)}
            loading={isLoading}
            className="outlined-btn">
            Set Default Version
          </Button>
          <Button
            appearance="primary"
            onClick={() => setOpenUpload(true)}
            startIcon={<AddIcon />}
            className="outlined-btn black-btn">
            Add Model Detection
          </Button>
        </div>
      </Toolbar>
      <CustomDialog
        open={isDefault}
        title="Set Default Version"
        close={() => setIsDefault(false)}>
        <SetDefaultForm
          close={() => setIsDefault(false)}
          fetchData={fetchData}
        />
      </CustomDialog>
      <CustomDialog
        open={openUpload}
        title="Add Model Detection"
        close={() => setOpenUpload(false)}>
        <ModelDetectionForm
          close={() => setOpenUpload(false)}
          fetchData={fetchData}
        />
      </CustomDialog>
      <CustomDialogIcon
        open={dialog}
        close={() => setDialog(false)}
        content={dialogContent}
        isLoading={isLoading}>
        <div className="usersTableToolbar__dialogList">
          {selected.map((x) => {
            return (
              <p
                key={x._id}
                className="">
                {x.title} - #{x._id}
              </p>
            );
          })}
        </div>
      </CustomDialogIcon>
    </>
  );
}
