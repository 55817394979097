import { imageBaseUrl } from "../users/helpers";
export default function AuthBackground() {
  return (
    <img
      className="auth-screen"
      src={`${imageBaseUrl}/login-screen.webp`}
      alt=""
    />
  );
}
