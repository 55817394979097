import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";

export default function TableHeadCellList({ data, orderBy, sortBy, createSortHandler }) {
  return (
    <>
      {data.map((cell) => (
        <TableCell
          key={cell.id}
          width={cell.width}
          sortDirection={sortBy === cell.id ? orderBy : false}>
          {cell.sortable ? (
            <TableSortLabel
              active={sortBy === cell.id}
              onClick={createSortHandler(cell.id)}
              direction={sortBy === cell.id ? orderBy : "asc"}>
              <span dangerouslySetInnerHTML={{ __html: cell.label }}></span>
              {sortBy === cell.id ? (
                <Box
                  component="span"
                  sx={visuallyHidden}>
                  {orderBy === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          ) : (
            <TableSortLabel
              hideSortIcon
              key={cell.id}>
              <span dangerouslySetInnerHTML={{ __html: cell.label }}></span>
            </TableSortLabel>
          )}
        </TableCell>
      ))}
    </>
  );
}
