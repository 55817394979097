import { TableCell, Tooltip } from "@mui/material";
import { formatBeforeUnderscore } from "../users/helpers";

export default function TableCellName({ name }) {
  return (
    <TableCell>
      {name ? (
        <Tooltip
          placement="top"
          title={name}>
          <span>{formatBeforeUnderscore(name)}_</span>
        </Tooltip>
      ) : (
        "N/A"
      )}
    </TableCell>
  );
}
