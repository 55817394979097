import { TableCell, Tooltip } from "@mui/material";
import { formatEmail } from "../users/helpers";

export default function TableCellEmail({ email }) {
  return (
    <TableCell>
      <Tooltip
        placement="top"
        title={email}>
        <span>{formatEmail(email)}</span>
      </Tooltip>
    </TableCell>
  );
}
