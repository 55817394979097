import React, { useState } from "react";
import { Switch, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { instance } from "../../routes/axios";
import { showSnackbar } from "../../store/snackbarSlice";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { formatDateTime } from "./helpers";
import TableBodyLoading from "../table/TableBodyLoading";
import EmptyDataMessage from "../table/EmptyDataMessage";
import DrillsHistoryUpload from "./DrillsHistoryUpload";
import { drillHistoryTableHead } from "./helpers";
import CTableHead from "../table/CTableHead";
import { Button } from "rsuite";
import CustomDialog from "../libs/CustomDialog";
import ReprocessForm from "../manage-videos/ReprocessForm";
import { formatToTwoDecimals } from "../users/helpers";
import DrillHistoryFilters from "../drills/DrillHistoryFilters";
import CTablePagination from "../table/CTablePagination";
import TableCellName from "../table/TableCellName";

export default function DrillsHistoryTable({ hidePagination }) {
  const [limit, setLimit] = useState(!hidePagination ? 10 : 5);
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const user = useSelector((state) => state.user);
  const [reprocessDialog, setReprocessDialog] = useState(false);
  const [editValues, setEditValues] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [scores, setScores] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [typesValues, setTypesValues] = useState([]);
  const [statusValues, setStatusValues] = useState([]);
  const [filteredCount, setFilteredCount] = useState(0);
  const isFilterApplied = useRef(false);

  async function fetchData() {
    if (!user?._id) return;
    setLoading(true);
    try {
      const [drillDetails, scoresData] = await Promise.all([
        instance.post("/drill-upload-details", {
          _id: user._id,
          page: page,
          limit,
          gameType: typesValues,
          status: statusValues,
          startDate: null,
          endDate: null,
        }),
        instance.post("/drill-scores", {
          userId: user._id,
        }),
      ]);

      setData(drillDetails?.data?.data?.data);
      setTotalCount(drillDetails?.data?.data?.pagination?.totalCount);
      setFilteredCount(drillDetails?.data?.data?.pagination?.filteredCount);
      setScores(scoresData?.data?.data);

      if (isFilterApplied.current && page !== 1) {
        return;
      }
    } catch (err) {
      console.error(err);
      dispatch(showSnackbar({ message: "Something went wrong", severity: "error" }));
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    isFilterApplied.current = true;
    setPage(1);
  }, [typesValues, statusValues, limit]);

  useEffect(() => {
    if (user?._id) {
      fetchData();
    }
  }, [user?._id, page, limit, typesValues, statusValues]);

  function findTotalScoreById(id) {
    const foundScores = scores?.find((x) => x.videoUploadsId === id);
    return formatToTwoDecimals(foundScores?.total_score || 0) + " %" || "N/A";
  }

  function handleNavigate(row) {
    navigate(`/users/${user._id}/drill-history/${row._id}`);
  }

  function openReprocessDialog(e, row) {
    e.stopPropagation();
    setReprocessDialog(true);
    setEditValues({
      analysis_version: row.analysis_version || "v1.0.0",
      model_detection_version: row.model_detection_version || 1,
      scoring_metrics_version: row.scoring_metrics_version || 1,
      _id: row._id,
    });
  }

  async function setActiveDrill(e, id, enabled) {
    try {
      await instance.put(`/drills/${id}?enable=${enabled}`);
      fetchData();
      dispatch(showSnackbar({ message: `Drill ${enabled ? "Activated" : "Deactivated"} successfully`, severity: "success" }));
    } catch (err) {
      console.log("Error in setActiveDrill:", err);
      dispatch(showSnackbar({ message: err?.response?.data?.message, severity: "error" }));
    }
  }

  // const handleDateChange = (range) => {
  //   if (!range || range.length === 0) return;
  //   setDateRange(range);
  //   fetchData(range);
  // };

  // const cleanDateChange = () => {
  //   setDateRange([]);
  //   fetchData([]);
  // };

  return (
    <>
      {!hidePagination && (
        <div className="main-content-px">
          <div className="filters-block filters-block-drills">
            <DrillHistoryFilters
              setStatusValues={setStatusValues}
              setTypesValues={setTypesValues}
            />
            <DrillsHistoryUpload fetchData={fetchData} />
          </div>
        </div>
      )}

      {data.length > 0 ? (
        <TableContainer>
          <Table
            sx={{
              minWidth: 900,
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}>
            <CTableHead
              rowCount={data.length}
              data={data}
              headData={drillHistoryTableHead}>
              <TableCell width="100"></TableCell>
              <TableCell width="40px"></TableCell>
            </CTableHead>

            <TableBody>
              {isLoading ? (
                <TableBodyLoading colSpan={drillHistoryTableHead.length + 1} />
              ) : (
                data.map((row, index) => {
                  return (
                    <TableRow
                      key={row._id}
                      onClick={() => handleNavigate(row)}
                      hover
                      style={{ cursor: "pointer" }}>
                      <TableCellName name={row.gameType} />
                      {/* <TableCell>{row.gameType}</TableCell> */}
                      <TableCell>{row.model_detection_version || "N/A"}</TableCell>
                      <TableCell>{row.analysis_version || "N/A"}</TableCell>
                      <TableCell>{row.scoring_metrics_version || "N/A"}</TableCell>
                      <TableCell>{findTotalScoreById(row._id)}</TableCell>
                      <TableCell>{formatDateTime(row.uploadDate)}</TableCell>
                      <TableCell>
                        <Switch
                          checked={row?.enabled}
                          onChange={(e) => setActiveDrill(e, row._id, e.target.checked)}
                          onClick={(e) => e.stopPropagation()}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell>
                        <p className={`drill-status ${row.status.toLowerCase()}`}>{row.status}</p>
                      </TableCell>
                      <TableCell>
                        <Button
                          appearance="ghost"
                          onClick={(e) => openReprocessDialog(e, row)}
                          className="outlined-btn black-outlined-btn">
                          Re-process
                        </Button>
                      </TableCell>
                      <TableCell width="7%">
                        <div className="flex items-center justify-end">
                          <KeyboardArrowRightIcon />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="main-content-px">
          <EmptyDataMessage />
          <br />
        </div>
      )}
      {!hidePagination && (
        <CTablePagination
          setPage={setPage}
          setLimit={setLimit}
          totalCount={filteredCount}
          limit={limit}
          page={page}
        />
      )}
      <CustomDialog
        open={reprocessDialog}
        title="Re-process"
        close={() => setReprocessDialog(false)}>
        <ReprocessForm
          close={() => setReprocessDialog(false)}
          editValues={editValues}
          fetchData={fetchData}
        />
      </CustomDialog>
    </>
  );
}
