import { useForm, Controller } from "react-hook-form";
import { useState, useCallback, useEffect } from "react";
import { DialogActions, DialogContent } from "@mui/material";
import { Button, SelectPicker } from "rsuite";
import AuthFormGroup from "../auth/AuthFormGroup";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../store/snackbarSlice";
import { instance } from "../../routes/axios";

export default function SetScoringMetricsDefualtForm({ close, fetchData }) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [scoringMetricsTypes, setScoringMetricsTypes] = useState([]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { analysis_version: "" },
    mode: "onChange",
  });

  const fetchScoringMetrics = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await instance.post("/scoring-metrics/fetch-all", {});
      if (res.data?.data?.allScoringMetrics) {
        const formattedData = res?.data?.data?.allScoringMetrics.map((versionObj) => ({
            label: `Score Version- ${versionObj?.version} (${versionObj?.name}) ${versionObj?.isDefault ? " - Default" : ""}`,
            value: versionObj?._id,
          })
        );
        setScoringMetricsTypes(formattedData);
      } else {
        setScoringMetricsTypes([]);
      }
    } catch (err) {
      dispatch(showSnackbar({ message: "Failed to fetch Scoring metrics", severity: "error" }));
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchScoringMetrics();
  }, [fetchScoringMetrics]);

  async function onSubmit(data) {
    setIsLoading(true);
    try {
      await instance.put(`scoring-metrics/${data?.scoring_metrics_version}/default`,{
        params: { _id: data?.scoring_metrics_version },
      });
      await fetchData();
      close();
      dispatch(
        showSnackbar({
          message: "Default Version has been successfully updated",
          severity: "success",
        })
      );
    } catch (err) {
      dispatch(
        showSnackbar({
          message: err?.response?.data?.message || "Something went wrong in onSubmit",
          severity: "error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <DialogContent
        dividers
        className="c-dialog__content">
        <div
          className="form prompts__form"
          id="scroll-dialog-description">
          <div className="form__fields">
          <AuthFormGroup
                label="Scoring Metrics Version"
                error={errors.scoring_metrics_version}>
                <Controller
                  name="scoring_metrics_version"
                  control={control}
                  render={({ field }) => (
                    <SelectPicker
                      size="lg"
                      id={field.name}
                      value={field.value}
                      data={scoringMetricsTypes}
                      searchable={false}
                      onChange={field.onChange}
                      placeholder="Select Scoring Metric Version"
                      menuStyle={{maxHeight: '150px', overflowY: 'auto'}}
                    />
                  )}
                />
              </AuthFormGroup>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          appearance="ghost"
          onClick={close}
          className="outlined-btn">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
          appearance="primary"
          className="outlined-btn black-btn min-width">
          Set Defualt
        </Button>
      </DialogActions>
    </>
  );
}
