import { Button } from "rsuite";
import EditIcon from "@mui/icons-material/Edit";
import { useChangePasswordDialog } from "../../dialog/hooks";
export default function SettingsPassword() {
  const { openChangePasswordDialog } = useChangePasswordDialog();

  return (
    <div className="page-block page-block-pd settings__password">
      <p className="title-sm text-greyTitle">Password</p>
      <p className="settings__password-title">Click below to update your password</p>
      <p className="settings__password-text">Last time updated : 12.09.2022</p>
      <Button
        onClick={openChangePasswordDialog}
        appearance="primary"
        className="black-btn outlined-btn mt-24"
        startIcon={<EditIcon />}>
        Change Password
      </Button>
    </div>
  );
}
