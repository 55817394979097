import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

//todo: check other modals

export default function CustomDialog({ open, close, title, children, maxWidth }) {
  return (
    <Dialog
      onClose={close}
      open={open}
      fullWidth
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth={maxWidth ? maxWidth : "sm"}
      className="c-dialog">
      <DialogTitle
        id="scroll-dialog-title"
        className="c-dialog__title">
        {title}
        <IconButton
          aria-label="close"
          onClick={close}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {children}
    </Dialog>
  );
}
