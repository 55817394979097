import { useDialogContext } from "./dialogProvider";
import ChangePasswordDialog from "../components/dialogs/ChangePasswordDialog";

export function useChangePasswordDialog() {
  const { openModal, closeModal } = useDialogContext();

  const openChangePasswordDialog = () => {
    openModal("Change password", ChangePasswordDialog);
  };

  return { openChangePasswordDialog, closeModal };
}
