import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { instance } from "../../routes/axios";
import { showSnackbar } from "../../store/snackbarSlice";

export default function DrillsIdNav({ setDrill, setScores, setData, setLoading, csvPage }) {
  const { drillId, id } = useParams();
  const user = useSelector((state) => state.user);
  const baseUrl = `/users/${user?._id}/drill-history/${drillId}`;

  return (
    <div className="userNav nav-drills">
      <div className="userNav__row main-content-px">
        <NavLink
          to={baseUrl}
          end>
          Overview
        </NavLink>
        <NavLink
          to={baseUrl + "/attempts"}
          className="todo">
          Drill Attempts
        </NavLink>
        <NavLink
          to={baseUrl + "/attempts"}
          className="todo">
          Event Log
        </NavLink>
        <NavLink to={baseUrl + "/files"}>Files</NavLink>
        <NavLink to={baseUrl + "/history-logs"}>History Logs</NavLink>
      </div>
    </div>
  );
}
