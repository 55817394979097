import UserIdLayout from "../components/users/UserIdLayout";
import Layout from "../components/Layout";
import VideocamIcon from "@mui/icons-material/Videocam";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useState, useEffect } from "react";
import { instance } from "../routes/axios";
import { showSnackbar } from "../store/snackbarSlice";
import DrillsIdNav from "../components/drills/DrillsIdNav";
import { formatStringClass, formatToTwoDecimals, getScoreStatusClass } from "../components/users/helpers";
import { Skeleton } from "@mui/material";
import { Tooltip } from "@mui/material";
import ClipPlayer from "../components/drills/ClipPlayer";

function DrillScoreItem({ data, title }) {
  return (
    <div>
      <Tooltip
        placement="top"
        title={data.description}>
        <div className="drillsOverview__item">
          <p className="text-greyTitle flex items-center justify-between">
            {title} <span className={`skills__status ${getScoreStatusClass(data?.scores?.raw_score)}`}>{formatStringClass(getScoreStatusClass(data?.scores?.raw_score))}</span>
          </p>
          <div className="flex items-center justify-between">
            <p className="drillsOverview__item-text font-medium">{formatToTwoDecimals(data?.scores?.raw_score)}%</p>
            <div className="flex items-center">
              <span className="skills__status mr">5 of 5</span>
              <VideocamIcon />
            </div>
          </div>
        </div>
      </Tooltip>
      <div className="drillsOverview__details">
        {data?.categories &&
          Object.entries(data?.categories).map(([key, value]) => (
            <div
              className="drillsOverview__details-item"
              key={key}>
              <div className="drillsOverview__details-top">
                <p>{key}</p>
                <span className={`skills__status ${getScoreStatusClass(value.scores?.score)}`}>{formatStringClass(getScoreStatusClass(value.scores?.score))}</span>
              </div>
              <p>
                <b>{value.scores?.score}%</b>
              </p>
              <div className="drillsOverview__details-progress">
                <div style={{ width: value.scores?.score + "%" }}></div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default function DrillHistoryIdPage() {
  const { drillId, id } = useParams();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [scores, setScores] = useState([]);
  const [drill, setDrill] = useState(null);
  const [highlights, setHighlights] = useState([]);

  const fetchData = useCallback(async () => {
    if (!user?._id) return;
    setLoading(true);
    try {
      const [videos, analysis, scores, highlights] = await Promise.all([instance.post("/drill-upload-details", { _id: id }), instance.post("/drill-analysis", { userId: id }), instance.post("/drill-scores", { userId: id }), instance.get(`/drills/${id}/highlights`)]);

      const foundDrillAnalysis = analysis.data.data.find((x) => x.videoUploadsId === drillId);
      const foundScores = scores.data.data.find((x) => x.videoUploadsId === drillId);
      const foundVideo = videos.data.data.data.find((x) => x._id === drillId);
      const foundHighlights = highlights.data.data.find((x) => x.videoUploadsId === drillId);

      setDrill(foundVideo);
      setData(foundDrillAnalysis);
      setScores(foundScores);
      setHighlights(foundHighlights);
    } catch (err) {
      console.error(err);
      dispatch(showSnackbar({ message: "Something went wrong", severity: "error" }));
    } finally {
      setLoading(false);
    }
  }, [id, drillId, user?._id, dispatch]);

  useEffect(() => {
    if (user?._id) {
      fetchData();
    }
  }, [fetchData]);

  return (
    <Layout>
      <UserIdLayout
        isDrill
        name={drill?.gameType || "N/A"}>
        <DrillsIdNav
          setDrill={setDrill}
          setScores={setScores}
          setData={setData}
          setLoading={setLoading}
        />
        <div className="drillsOverview">
          <div className="main-content-px">
            <p className="drillsOverview__version">
              Model Detection version: <span> {drill?.model_detection_version || "N/A"}</span>
            </p>
            <p className="drillsOverview__version">
              Analysis Script version: <span>{drill?.analysis_version || "N/A"}</span>
            </p>
            <p className="drillsOverview__version">
              Scoring Metrics version: <span>{drill?.scoring_metrics_version || "N/A"}</span>
            </p>
            <br />
          </div>
          <div className="drillsOverview__block main-content-px">
            <div>
              <p className="drillsOverview__block-small">Total Drill Score</p>
              <p className="drillsOverview__block-value">{formatToTwoDecimals(scores?.total_score || 0)}%</p>
            </div>
            <div className="drillsOverview__block-list">
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={118}
                />
              ) : (
                data?.performance_metrics &&
                scores?.areas &&
                Object.entries(scores.areas).map(([key, value]) => (
                  <DrillScoreItem
                    key={key}
                    title={key}
                    data={value}
                  />
                ))
              )}
            </div>
          </div>
          <div className="drillsOverview__block main-content-px">
            <div>
              <p className="drillsOverview__block-small">Uploaded video</p>
              <p className="drillsOverview__block-value">1</p>
            </div>
            <div className="drillsOverview__video">
              {isLoading && (
                <>
                  <Skeleton
                    variant="rectangular"
                    height={118}
                  />
                  <Skeleton />
                  <Skeleton width="60%" />
                </>
              )}
              {!isLoading && drill && (
                <video
                  className="drillsOverview__video"
                  controls>
                  <source
                    src={drill?.videoUrl}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          </div>
          <div className="drillsOverview__block main-content-px">
            <div>
              <p className="drillsOverview__block-small">Highlights</p>
              <p className="drillsOverview__block-value">{highlights?.activities?.length}</p>
            </div>
            <div className="drillsOverview__highlights">
              {highlights?.activities?.map((highlight) => {
                const matchingScore = scores?.activity_timeline?.activities?.find((score) => score?.type === highlight?.type && score?.frames.start === highlight?.frames.start);
                const rawScore = matchingScore?.raw_score.toFixed(2) ?? "N/A";                
                return isLoading ? (
                  <>
                    <Skeleton
                      variant="rectangular"
                      height={118}
                    />
                    <Skeleton />
                    <Skeleton width="60%" />
                  </>
                ) : (
                  <div>
                    <ClipPlayer
                      src={drill?.videoUrl}
                      startTime={Number(highlight?.frames.start) / 30}
                      endTime={Number(highlight?.frames.end) / 30}
                    />
                    <p className="drillsOverview__highlights-text">{highlight?.type}</p>
                    <p className="drillsOverview__highlights-text">Score: {rawScore}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="drillsOverview__block main-content-px">
            <div>
              <p className="drillsOverview__block-small">Drill Attempts</p>
              <p className="drillsOverview__block-value">{drill?.processing_attempts}</p>
            </div>
            <div className="drillsOverview__block-list">
              {/* <DrillScoreItem />
              <DrillScoreItem />
              <DrillScoreItem /> */}
            </div>
          </div>
        </div>
      </UserIdLayout>
    </Layout>
  );
}
