import Layout from "../components/Layout";
import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { instance } from "../routes/axios";
import { showSnackbar } from "../store/snackbarSlice";
import { Switch, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { formatDateTime, analysisVersionTableHead } from "../components/users/helpers";
import CTableHead from "../components/table/CTableHead";
import TableBodyLoading from "../components/table/TableBodyLoading";
import CTableRowCheckbox from "../components/table/CTableRowCheckbox";
import ConfigurationsNav from "../components/configurations/ConfigurationsNav";
import AnalysisVersionsToolbar from "../components/configurations/AnalysisVersionsToolbar";

export default function AnalysisVersionsPage() {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("asc");
  const [sortBy, setSortBy] = useState("created_at");

  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const res = await instance.get("/drill-analysis/versions", {});
      setData(res?.data?.data);
    } catch (err) {
      dispatch(showSnackbar({ message: "Something went wrong", severity: "error" }));
    } finally {
      setLoading(false);
    }
  }, [dispatch, page, limit, orderBy, sortBy]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleToggleEnabled = async (row) => {
    setLoading(true);
    try {
      await instance.put(`/analysis-version/${row?._id}`, null, {
        params: { enabled: !row?.enabled },
      });
      dispatch(
        showSnackbar({
          message: `Version ${row?.title} (${row?.version}) has been ${row?.enabled ? "disabled" : "enabled"}.`,
          severity: "success",
        })
      );
      fetchData();
    } catch (err) {
      dispatch(
        showSnackbar({
          message: err?.response?.data?.message || "Something went wrong",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <ConfigurationsNav />
      <AnalysisVersionsToolbar
        selected={selected}
        setSelected={setSelected}
        fetchData={fetchData}
      />
      <TableContainer>
        <Table
          sx={{ minWidth: 900 }}
          aria-labelledby="tableTitle">
          <CTableHead
            setOrderBy={setOrderBy}
            setSortBy={setSortBy}
            data={data}
            setSelected={setSelected}
            headData={analysisVersionTableHead}
            orderBy={orderBy}
            sortBy={sortBy}
            numSelected={selected.length}
            rowCount={data.length}
            >
            <TableCell width="40px"></TableCell>
          </CTableHead>

          <TableBody>
            {isLoading ? (
              <TableBodyLoading colSpan={analysisVersionTableHead.length + 1} />
            ) : (
              data.map((row, index) => {
                const isItemSelected = selected.some((obj) => obj._id === row._id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    tabIndex={-1}
                    aria-checked={isItemSelected}
                    selected={isItemSelected}
                    key={row?._id}
                    sx={{ cursor: "pointer" }}>
                    <CTableRowCheckbox
                      _id={row?._id}
                      data={data}
                      labelId={labelId}
                      isItemSelected={isItemSelected}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <TableCell>{row?._id}</TableCell>
                    <TableCell>{row?.title}</TableCell>
                    <TableCell>{row?.version}</TableCell>
                    <TableCell>{formatDateTime(row?.createdAt)}</TableCell>
                    <TableCell>{formatDateTime(row?.updatedAt)}</TableCell>
                    <TableCell>
                      <Switch
                        checked={row?.enabled}
                        onChange={() => handleToggleEnabled(row)}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>
                      {row?.isDefault && 
                        <div className={`drill-status processed`}>
                          <p style={{padding:"5px"}} >{"Default"}</p> 
                        </div>
                      }
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Layout>
  );
}
