import { Avatar } from "rsuite";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Skeleton } from "@mui/material";
import { DateRangePicker } from "rsuite";
import { Input, InputGroup } from "rsuite";
import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../helpers/useDebounce";
import { showSnackbar } from "../../store/snackbarSlice";
import { instance } from "../../routes/axios";
import { formatDateTime, imageBaseUrl } from "./helpers";
import EmptyDataMessage from "../table/EmptyDataMessage";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "rsuite";
import CustomDialogIcon from "../libs/CustomDialogIcon";

export default function ChatsList({ hideFilter }) {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [dialog, setDialog] = useState(false);

  const fetchData = useCallback(
    async (search = "", range = []) => {
      if (!user?._id) return;
      setLoading(true);
      try {
        const res = await instance.post("/get-coach-chats", {
          user: user._id,
          search: search,
          startDate: range ? range[0] : null,
          endDate: range ? range[1] : null,
        });
        setData(res.data.data.chats);
        setTotalCount(res.data.data.totalCount);
      } catch (err) {
        console.error(err);
        dispatch(showSnackbar({ message: "Something went wrong", severity: "error" }));
      } finally {
        setLoading(false);
      }
    },
    [user, dispatch]
  );

  const dialogContent = {
    title: "Delete Coach Chats",
    icon: <DeleteIcon />,
    text: "Are you sure you want to delete the Coach Chats?",
    buttonText: "Delete",
    buttonAction: deleteModel,
  };

  async function deleteModel() {
    setLoading(true);
    try {
      await instance.delete("/delete-coach-chats", {
        data: { user: user._id },
      });
      dispatch(
        showSnackbar({
          message: `Coach Chats has been deleted`,
          severity: "success",
        })
      );
      fetchData();
      setDialog(false);
    } catch (err) {
      dispatch(showSnackbar({ message: "Something went wrong", severity: "error" }));
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const debouncedFetchData = useDebounce(fetchData, 500);

  const handleSearchChange = (value) => {
    setSearchValue(value);
    debouncedFetchData(value, dateRange);
  };

  const handleDateChange = (range) => {
    setDateRange(range);
    fetchData(searchValue, range);
  };

  const cleanDateChange = () => {
    setDateRange([]);
    fetchData(searchValue, []);
  };

  function extractVideoInfo(markdown) {
    const regex = /<video\b[^>]*>[\s\S]*?{\s*videoUrl\s*:\s*"([^"]+)"\s*,\s*startFrame\s*:\s*(\d+)\s*,\s*endFrame\s*:\s*(\d+)\s*}[\s\S]*?(?:<\/video>|<video\b[^>]*>)/i;
    const match = markdown.match(regex);
    if (match) {
      return {
        videoUrl: match[1],
        startFrame: parseInt(match[2], 10),
        endFrame: parseInt(match[3], 10),
      };
    }
    return null;
  }

  const VideoRenderer = ({ value }) => {
    try {
      const videoData = extractVideoInfo(value);
      const { videoUrl, startFrame, endFrame } = videoData;
      if (!videoUrl) return <p>Something went wrong</p>;
      const startTime = startFrame ? (startFrame / 30).toFixed(1) : 0;
      const endTime = endFrame ? (endFrame / 30).toFixed(1) : "";
      return (
        <video
          width="600"
          controls>
          <source
            src={`${videoUrl}#t=${startTime},${endTime}`}
            type="video/mp4"
          />
          Video is not supported
        </video>
      );
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return <p>Error loading video.</p>;
    }
  };

  return (
    <>
      {!hideFilter && (
        <div>
          <div className="filters-block chats__filters main-content-px">
            <div>
              <p className="filter-label">Search</p>
              <InputGroup
                size="lg"
                disabled={totalCount < 1}>
                <Input
                  placeholder="Search messages..."
                  onChange={(value) => handleSearchChange(value)}
                />
              </InputGroup>
            </div>
            <div>
              <p className="filter-label">Date</p>
              <DateRangePicker
                disabled={totalCount < 1}
                caretAs={null}
                size="lg"
                value={dateRange}
                onChange={handleDateChange}
                onClean={cleanDateChange}
              />
            </div>
            <Button
              appearance="ghost"
              disabled={data.length < 1}
              onClick={() => setDialog(true)}
              loading={isLoading}
              className="outlined-btn red-outlined-btn">
              Delete Coach Chats
            </Button>
          </div>
        </div>
      )}
      <div className={`${!hideFilter ? "activity main-content-px" : ""}`}>
        {isLoading ? (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        ) : (
          <div className="chats__list">
            {data.length > 0 ? (
              (hideFilter ? [...data].reverse() : data).map((x) => (
                <div
                  key={x._id}
                  className={`chats__item ${x.type === "human" ? "user-style" : ""}`}>
                  <Avatar
                    size="md"
                    circle
                    src={x.type === "human" ? user?.profilePicture : `${imageBaseUrl}/ai-coach.webp`}
                  />
                  <div>
                    <div className="chats__item-header">
                      <p>{x.type === "human" ? user?.name : "AI Coach"}</p>
                      <p>{formatDateTime(x.created_at)}</p>
                    </div>
                    <Markdown
                      remarkPlugins={[remarkGfm]}
                      components={{
                        code({ inline, children }) {
                          const text = String(children).trim();
                          if (!inline && text.includes("<video>")) {
                            return <VideoRenderer value={text} />;
                          }
                          return <code>{children}</code>;
                        },
                      }}>
                      {x.content}
                    </Markdown>
                  </div>
                </div>
              ))
            ) : (
              <EmptyDataMessage />
            )}
          </div>
        )}
      </div>
      <CustomDialogIcon
        open={dialog}
        close={() => setDialog(false)}
        content={dialogContent}
        isLoading={isLoading}></CustomDialogIcon>
    </>
  );
}
