import MultipleAutocomplete from "../libs/MultipleAutocomplete";
import { useState, useEffect, useCallback } from "react";
import { instance } from "../../routes/axios";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../store/snackbarSlice";
import { statusOptions } from "../users/helpers";

export default function ManageVideosFilters({ setModelValues, setAnalysisValues, setStatusValues }) {
  const [modelDetectionTypes, setModelDetectionTypes] = useState([]);
  const [analysisVersionsTypes, setAnalysisVersionsTypes] = useState([]);
  const dispatch = useDispatch();

  const fetchVersions = useCallback(async () => {
    try {
      const modelDetectionRes = await instance.post("/fetch-all-yolo-versions", {});
      if (modelDetectionRes.data?.data?.versions) {
        const formattedData = modelDetectionRes.data.data.versions.map((versionObj, index) => ({
          label: `Model Version ${versionObj.version} (${versionObj.actualVersionValue})`,
          value: versionObj.version,
        }));
        setModelDetectionTypes(formattedData);
      } else {
        setModelDetectionTypes([]);
      }
      const analysisVersionsRes = await instance.get("/drill-analysis/versions", {});
      if (analysisVersionsRes?.data?.data) {
        const formattedData = analysisVersionsRes?.data?.data?.map((x) => {
          return {
            label: `${x.title} (${x.version})`,
            value: x.version,
          };
        });
        setAnalysisVersionsTypes(formattedData);
      } else {
        setAnalysisVersionsTypes([]);
      }
    } catch (err) {
      dispatch(showSnackbar({ message: "Something went wrong", severity: "error" }));
    }
  }, [dispatch]);

  useEffect(() => {
    fetchVersions();
  }, [fetchVersions]);

  return (
    <>
      <MultipleAutocomplete
        options={modelDetectionTypes}
        label="Model Detection Version"
        onChange={(newValues) => setModelValues(newValues)}
      />
      <MultipleAutocomplete
        options={analysisVersionsTypes}
        label="Analysis Version"
        onChange={(newValues) => setAnalysisValues(newValues)}
      />
      <MultipleAutocomplete
        options={statusOptions}
        label="Status"
        onChange={(newValues) => setStatusValues(newValues)}
      />
    </>
  );
}
