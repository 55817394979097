import UserIdLayout from "../components/users/UserIdLayout";
import Layout from "../components/Layout";
import { useParams } from "react-router-dom";
import { useCallback, useState, useEffect } from "react";
import { instance } from "../routes/axios";
import DrillsIdNav from "../components/drills/DrillsIdNav";
import DownloadIcon from "@mui/icons-material/Download";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DrillsIdCsvExpandRow from "../components/drills/DrillsIdCsvExpandRow";
import { formatDateTime } from "../components/users/helpers";

export default function DrillHistoryIdCsvPage() {
  const { drillId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [otherFiles, setOtherFiles] = useState(null);
  const [jsonUploads, setJsonUploads] = useState(null);

  const fetchCsvUploads = useCallback(async () => {
    setLoading(true);
    try {
      const res = await instance.get(`/csv-uploads/${drillId}`);
      setOtherFiles(res.data.data);
      setLoading(false);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }, [drillId]);

  const fetchJsonUploads = useCallback(async () => {
    setLoading(true);
    try {
      const res = await instance.get(`/drill/${drillId}`);
      setJsonUploads(res.data.data);
      setLoading(false);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }, [drillId]);

  useEffect(() => {
    fetchCsvUploads();
    fetchJsonUploads();
  }, [fetchCsvUploads, fetchJsonUploads]);

  return (
    <Layout>
      <UserIdLayout isDrill>
        <DrillsIdNav csvPage />
        <div className="drillsCsv">
          <TableContainer>
            <Table sx={{ minWidth: 900 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Generated at</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <DrillsIdCsvExpandRow
                  name="Drill Analysis"
                  data={jsonUploads?.drillAnalysis}
                  isAnalysis
                />
                <DrillsIdCsvExpandRow
                  name="Drill Scores"
                  data={jsonUploads?.drillScores}
                  isScores
                />
                <TableRow className={`drillsCsv__expand ${otherFiles && Object.keys(otherFiles).length === 0 ? "disabled" : ""}`}>
                  <TableCell>CSV file</TableCell>
                  <TableCell>{formatDateTime(otherFiles?.uploadedDate)}</TableCell>
                  <TableCell>
                    <a
                      download
                      href={otherFiles?.url}
                      className="drillsCsv__downloadBtn">
                      <DownloadIcon />
                    </a>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </UserIdLayout>
    </Layout>
  );
}
