import { Input, Button, SelectPicker } from "rsuite";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import AuthFormGroup from "../auth/AuthFormGroup";
import { useState, useEffect, useMemo } from "react";
import { instance } from "../../routes/axios";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../store/snackbarSlice";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import { Tag, TagGroup, TagInput, Message } from "rsuite";
import { DialogContent, Tooltip, DialogActions } from "@mui/material";
import { toSnakeCase } from "../users/helpers";

const prompt_type_data = [
  { label: "SYSTEM_PROMPT", value: "SYSTEM_PROMPT" },
  { label: "SPECIALISED_PROMPT", value: "SPECIALISED_PROMPT" },
];

const access_type_data = [
  { label: "ADMIN_ACCESS", value: "ADMIN_ACCESS" },
  { label: "ALL_USER", value: "ALL_USER" },
  { label: "INTERNAL_USER", value: "INTERNAL_USER" },
  { label: "SPECIFIC_USER", value: "SPECIFIC_USER" },
];

export default function PromptUploadForm({ switchVersion, close, fetchData, editValues, isEdit, _id }) {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [content, setContent] = useState(editValues?.content || "");
  const [missingVariables, setMissingVariables] = useState([]);
  const [activeVersion, setActiveVersion] = useState(editValues?.active_version);

  // const defaultValues = editValues ? editValues : { title: "", content: "", prompt_type: "", access_type: "", template_text: [] };
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors, isDirty, dirtyFields },
  } = useForm({
    defaultValues: useMemo(() => {
      return editValues ? editValues : { title: "", content: "", prompt_type: "", access_type: "", template_text: [] };
    }, [editValues]),
    mode: "onChange",
  });

  useEffect(() => {
    reset(editValues);
    setContent(editValues?.content || "");
    setValue("content", editValues?.content || "");
  }, [setValue, editValues, reset]);

  const templateText = watch("template_text");
  const isTagInText = (tag) => getValues("content").includes(tag);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "template_text",
  });

  async function onSubmit(data) {
    delete data.history;
    delete data._id;
    delete data.active_version;
    const historyId = data.historyId;
    delete data.historyId;

    const changedData = !dirtyFields.specific_user
      ? Object.keys(dirtyFields).reduce((acc, key) => {
          acc[key] = data[key];
          return acc;
        }, {})
      : data;

    setLoading(true);
    try {
      const res = isEdit ? (!activeVersion ? await instance.put(`/prompts/${_id}`, { ...data }) : await instance.put(`/prompts/${_id}/history/${historyId}`, { ...changedData })) : await instance.post("/prompts", { ...data });
      reset();
      fetchData();
      close();
      dispatch(showSnackbar({ message: `${isEdit ? "Update" : "Upload"} has been successfully done`, severity: "success" }));
    } catch (err) {
      dispatch(showSnackbar({ message: err.response.data.message || "Something went wrong", severity: "error" }));
    } finally {
      setLoading(false);
    }
  }

  const addNewField = () => {
    append({ variable_name: "", description: "" });
  };

  function addTagInContent(tag) {
    const textarea = document.getElementById("content");
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const before = getValues("content").substring(0, start);
    const after = getValues("content").substring(end);

    const updatedContent = `${before}${tag}${after}`;
    setContent(updatedContent);
    setValue("content", updatedContent);
  }

  function getUniqueVariablesFromContent(content) {
    const variableRegex = /{{(.*?)}}/g;
    const foundVariables = [...content.matchAll(variableRegex)].map((match) => match[1]);
    return [...new Set(foundVariables)];
  }

  function checkMissingVariables() {
    const uniqueVariablesInContent = getUniqueVariablesFromContent(content);
    const templateVariables = templateText?.map((x) => x.variable_name);
    const missing = uniqueVariablesInContent.filter((variable) => !templateVariables.includes(variable));
    setMissingVariables(missing);
  }

  useEffect(() => {
    checkMissingVariables();
  }, [content, templateText]);

  const allAvailableVersions = editValues?.history.map((x) => {
    return {
      label: `Version ${x.version}`,
      value: x.version,
    };
  });
  const versionsData = [{ label: "Default", value: null }, ...(Array.isArray(allAvailableVersions) ? allAvailableVersions : [])];

  function handleSwitchVersion(value) {
    setActiveVersion(value);
    switchVersion(value, _id);
  }

  const accessTypeWatch = watch("access_type");

  return (
    <>
      <DialogContent
        className="c-dialog__content"
        dividers>
        <div
          className="form prompts__form"
          id="scroll-dialog-description">
          <div className="form__fields">
            {isEdit && (
              <AuthFormGroup label="Change editing version:">
                <SelectPicker
                  size="lg"
                  value={activeVersion}
                  data={versionsData}
                  cleanable={false}
                  searchable={false}
                  onChange={handleSwitchVersion}
                />
              </AuthFormGroup>
            )}
            <AuthFormGroup
              label="Title"
              error={errors.title}>
              <Controller
                name="title"
                control={control}
                rules={{
                  required: true,
                  maxLength: {
                    value: 255,
                    message: "* Title cannot exceed more than 255 characters",
                  },
                }}
                render={({ field }) => (
                  <Input
                    size="lg"
                    id={field.name}
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                    placeholder="Enter title"
                  />
                )}
              />
            </AuthFormGroup>

            <div className="">
              {missingVariables.length > 0 && (
                <Message type="warning">
                  <strong>
                    The following variables are missing in the form: <br />
                  </strong>
                  <TagGroup>
                    {missingVariables?.map((x, index) => {
                      const hasError = errors?.template_text?.[index]?.variable_name;
                      if (hasError) return null;
                      return (
                        <Tag
                          style={{ background: "white" }}
                          key={x + index}>
                          {x}
                        </Tag>
                      );
                    })}
                  </TagGroup>
                </Message>
              )}
              <AuthFormGroup
                label="Content"
                error={errors.content}>
                <Controller
                  name="content"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: {
                      value: 100000,
                      message: "* Content cannot exceed more than 100 000 characters",
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      size="lg"
                      as="textarea"
                      rows={6}
                      id={field.name}
                      value={content}
                      onChange={(value) => {
                        field.onChange(value);
                        setContent(value);
                        setValue("content", value);
                      }}
                      placeholder="Enter content"
                    />
                  )}
                />
              </AuthFormGroup>
              {templateText?.length > 0 && (
                <div className="prompts__tags">
                  {templateText?.map((x, index) => {
                    const hasError = errors?.template_text?.[index]?.variable_name;
                    if (hasError) return null;
                    return (
                      <Tooltip
                        placement="top"
                        key={x.variable_name + index}
                        title={x.description}>
                        <Tag
                          style={{ cursor: "pointer", backgroundColor: isTagInText(`{{${x.variable_name}}}`) ? "#cfe7f2" : "" }}
                          onClick={() => addTagInContent(`{{${x.variable_name}}}`)}>
                          {x.variable_name}
                        </Tag>
                      </Tooltip>
                    );
                  })}
                </div>
              )}
            </div>

            <AuthFormGroup
              label="Prompt type"
              error={errors.prompt_type}>
              <Controller
                name="prompt_type"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <SelectPicker
                    size="lg"
                    id={field.name}
                    value={field.value}
                    data={prompt_type_data}
                    searchable={false}
                    onChange={(value) => field.onChange(value)}
                    placeholder="Select prompt type"
                  />
                )}
              />
            </AuthFormGroup>

            <AuthFormGroup
              label="Access type"
              error={errors.access_type}>
              <Controller
                name="access_type"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <SelectPicker
                    size="lg"
                    id={field.name}
                    value={field.value}
                    data={access_type_data}
                    searchable={false}
                    onChange={(value) => field.onChange(value)}
                    placeholder="Select access type"
                  />
                )}
              />
            </AuthFormGroup>

            {accessTypeWatch === "SPECIFIC_USER" && (
              <AuthFormGroup
                label="Users emails"
                error={errors.specific_user}>
                <Controller
                  name="specific_user"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <TagInput
                      size="lg"
                      id={field.name}
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                    />
                  )}
                />
              </AuthFormGroup>
            )}

            <div className="">
              {fields.map((item, index) => (
                <div
                  key={item.id}
                  className="prompts__form-template">
                  <AuthFormGroup
                    label="Variable name"
                    error={errors.template_text?.[index]?.variable_name}>
                    <Controller
                      name={`template_text[${index}].variable_name`}
                      control={control}
                      rules={{
                        required: "Variable name is required",
                        validate: (value) => {
                          if (index === getValues("template_text").length - 1) {
                            const templateText = getValues("template_text");
                            const duplicates = templateText.filter((item, idx) => item.variable_name === value && idx !== index);
                            if (duplicates.length > 0) {
                              return "* Variable name must be unique";
                            }
                          }
                          return true;
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          size="lg"
                          id={field.name}
                          value={field.value}
                          onChange={(value) => {
                            field.onChange(toSnakeCase(value));
                            setValue(`template_text.${index}.variable_name`, value);
                            checkMissingVariables();
                          }}
                          placeholder="variable_name_example"
                        />
                      )}
                    />
                  </AuthFormGroup>

                  <AuthFormGroup
                    error={errors.template_text?.[index]?.description}
                    label="Description">
                    <Controller
                      name={`template_text[${index}].description`}
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <Input
                          size="lg"
                          id={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          placeholder="Description"
                        />
                      )}
                    />
                  </AuthFormGroup>

                  <Button
                    appearance="ghost"
                    onClick={() => remove(index)}
                    className="outlined-btn red-outlined-btn">
                    <ClearIcon />
                  </Button>
                </div>
              ))}
              <Button
                appearance="ghost"
                onClick={addNewField}
                startIcon={<AddIcon />}
                className="outlined-btn">
                Add template text
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="c-dialog__btns">
          <Button
            appearance="ghost"
            onClick={close}
            className="outlined-btn">
            Cancel
          </Button>
          <Button
            appearance="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={isEdit && !isDirty}
            loading={isLoading}
            className="outlined-btn black-btn min-width">
            {isEdit ? "Update" : "Upload"}
          </Button>
        </div>
      </DialogActions>
    </>
  );
}
