import { imageBaseUrl } from "../users/helpers";
export default function AuthHeading({ title, subtitle }) {
  return (
    <div className="auth__heading">
      <div className="auth__heading-row">
        <p
          className="auth__title"
          dangerouslySetInnerHTML={{ __html: title }}></p>
        <img
          src={`${imageBaseUrl}/logo.svg`}
          alt="logo"
          loading="lazy"
        />
      </div>
      <p className="auth__subtitle">{subtitle}</p>
    </div>
  );
}
