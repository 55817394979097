import UserIdLayout from "../components/users/UserIdLayout";
import Layout from "../components/Layout";
import { useParams } from "react-router-dom";
import { useCallback, useState, useEffect } from "react";
import { instance } from "../routes/axios";
import DrillsIdNav from "../components/drills/DrillsIdNav";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { formatDateTime } from "../components/users/helpers";

export default function DrillHistoryIdHistoryLogsPage() {
  const { drillId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [historyLogs, setHistoryLogs] = useState([]);

  const fetchHistoryLogs = useCallback(async () => {
    setLoading(true);
    try {
      const res = await instance.get(`/drills/${drillId}/history-logs`);
      setHistoryLogs(res.data.data.history_log || []);
    } catch (err) {
      console.error("Error fetching history logs:", err);
    } finally {
      setLoading(false);
    }
  }, [drillId]);

  useEffect(() => {
    fetchHistoryLogs();
  }, [fetchHistoryLogs]);

  return (
    <Layout>
      <UserIdLayout isDrill>
        <DrillsIdNav csvPage />
        <div className="drillsCsv">
          <TableContainer>
            <Table sx={{ minWidth: 900 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell>Datetime</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {historyLogs.length > 0 ? (
                  historyLogs.map((log, index) => (
                    <TableRow key={index}>
                      <TableCell>{log.type}</TableCell>
                      <TableCell>{log.msg || "No message"}</TableCell>
                      <TableCell>{formatDateTime(log.datetime)}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      No history logs available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </UserIdLayout>
    </Layout>
  );
}
