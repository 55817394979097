import axios from "axios";

const baseUrl = window._env_?.REACT_APP_ADMIN_API_URL ? `${window._env_?.REACT_APP_ADMIN_API_URL}/restapi` : "http://localhost:8080/restapi";
// const baseUrl = "https://dev-admin-restapi.aim-football.com/restapi";
export const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    Authorization: localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "",
  },
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      logout();
    }
    return Promise.reject(error);
  }
);

export function logout() {
  localStorage.removeItem("token");
  localStorage.removeItem("admin");
  window.location.href = "/";
}
