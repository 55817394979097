import { useRef, useEffect } from "react";

export default function ClipPlayer({ src, startTime, endTime }) {
  const mediaRef = useRef(null);

  useEffect(() => {
    const media = mediaRef.current;
    if (media) {
      media.currentTime = startTime;
    }
  }, [startTime, src]);

  const handleTimeUpdate = () => {
    const media = mediaRef.current;
    if (media) {
      if (media.currentTime < startTime) {
        media.currentTime = startTime;
      }
      if (media.currentTime >= endTime) {
        media.pause();
        media.currentTime = startTime;
      }
    }
  };

  return (
    <video
      ref={mediaRef}
      controls
      onTimeUpdate={handleTimeUpdate}>
      <source src={src} />
    </video>
  );
}
