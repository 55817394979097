import { useState } from "react";
import { instance } from "@routes/axios";
import { useDispatch } from "react-redux";
import { showSnackbar } from "@store/snackbarSlice";

export function useChangePassword() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const changePassword = async (data, email, onSuccess) => {
    setIsLoading(true);
    try {
      await instance.post("/change-password", {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
        email,
      });

      dispatch(
        showSnackbar({
          message: "Password has been successfully changed",
          severity: "success",
        })
      );

      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      dispatch(
        showSnackbar({
          message: "Something went wrong",
          severity: "error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return { changePassword, isLoading };
}
