import { Menu, NotificationsNone } from "@mui/icons-material";
import { getAdminNameLetters } from "./users/helpers";
import { imageBaseUrl } from "./users/helpers";
import { useSelector, useDispatch } from "react-redux";
import { setAdminData } from "../store/adminSlice";
import { instance } from "../routes/axios";
import { useCallback, useEffect } from "react";

export default function Header({ handleDrawer }) {
  const dispatch = useDispatch();
  const { data: admin, loading, isInitialized } = useSelector((state) => state.admin);

  const fetchData = useCallback(async () => {
    if (isInitialized) return;

    const email = localStorage.getItem("email");
    try {
      const res = await instance.post("/fetch-admin-details", { email });
      dispatch(setAdminData(res?.data));
    } catch (err) {
      console.error("Error fetching admin details:", err);
    }
  }, [dispatch, isInitialized]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <header className="header main-content-px">
      <div className="header__left">
        <button
          className="header__menuBtn"
          onClick={() => handleDrawer((prev) => !prev)}>
          <Menu />
        </button>
        <img
          src={`${imageBaseUrl}/logo-white.svg`}
          alt="logo"
        />
      </div>
      <div className="header__right">
        {/* <p className="header__search">search</p> */}
        <NotificationsNone />
        <div className="header__user">
          <div className="header__user-icon">{getAdminNameLetters(admin?.firstName, admin?.lastName)}</div>
        </div>
      </div>
    </header>
  );
}
