import CountryFlag from "../libs/CountryFlag";
import { useSelector } from "react-redux";
import moment from "moment";

export default function PersonalDetailsBlock() {
  const user = useSelector((state) => state.user);

  const age = user?.birthday ? moment().diff(user.birthday, "years") : "-";

  return (
    <div className="userIdGroup__block border-right main-content-px">
      <p className="title-sm text-greyTitle">Personal details</p>
      <div className="personalDetails__list">
        <div className="personalDetails__item">
          <p>Full name</p>
          <p>
            <span>{user?.name}</span>
          </p>
        </div>
        <div className="personalDetails__item">
          <p>Country</p>
          <p>
            {/* <CountryFlag country="US" /> */}
            <span>{user?.country || "-"}</span>
          </p>
        </div>
        <div className="personalDetails__item">
          <p>Email</p>
          <p>
            <span className="text-linkColor">{user?.userId}</span>
          </p>
        </div>
        <div className="personalDetails__item">
          <p>Gender</p>
          <p>
            <span>{user?.gender || "-"}</span>
          </p>
        </div>
        <div className="personalDetails__item">
          <p>Age</p>
          <p>
            <span>{age} yo</span>
          </p>
        </div>
        <div className="personalDetails__item">
          <p>Date of birth</p>
          <p>
            <span>{user?.birthday ? moment(user.birthday, ["MM-DD-YYYY", "YYYY-MM-DD"]).format("MM-DD-YYYY") : "-"}</span>
          </p>
        </div>
        <div className="personalDetails__item">
          <p>*Height</p>
          <p>
            <span>170 cm (5’7)</span>
          </p>
        </div>

        <div className="personalDetails__item">
          <p>*Position</p>
          <p>
            <span>Central Defender</span>
          </p>
        </div>
        <div className="personalDetails__item">
          <p>*Strong foot</p>
          <p>
            <span>Both</span>
          </p>
        </div>
        <hr />
        <p>Device info:</p>
        <div className="personalDetails__item">
          <p>Device id</p>
          <p>
            <span>{user?.deviceInfo?.deviceId || "-"}</span>
          </p>
        </div>
        <div className="personalDetails__item">
          <p>Base OS</p>
          <p>
            <span>{user?.deviceInfo?.baseOs || "-"}</span>
          </p>
        </div>
        <div className="personalDetails__item">
          <p>Device type</p>
          <p>
            <span>{user?.deviceInfo?.deviceType || "-"}</span>
          </p>
        </div>
        <div className="personalDetails__item">
          <p>Sys version</p>
          <p>
            <span>{user?.deviceInfo?.systemVersion || "-"}</span>
          </p>
        </div>
        <div className="personalDetails__item">
          <p>User agent</p>
          <p>
            <span>{user?.deviceInfo?.userAgent || "-"}</span>
          </p>
        </div>
      </div>
    </div>
  );
}
