import React, { createContext, useState, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const DialogContext = createContext();

export function DialogProvider({ children }) {
  const [modal, setModal] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
    contentComponent: null,
    data: null,
  });

  const openModal = (title, contentComponent, contentProps) => {
    setModal({
      isOpen: true,
      title,
      contentComponent,
      ...contentProps,
    });
  };

  const setTitle = (title) => setModal((prev) => ({ ...prev, title }));
  const setSubtitle = (subtitle) =>
    setModal((prev) => ({
      ...prev,
      subtitle,
    }));

  const closeModal = () => setModal((prev) => ({ ...prev, isOpen: false }));

  return (
    <DialogContext.Provider
      value={{
        openModal,
        closeModal,
        setTitle,
        setSubtitle,
      }}>
      {children}
      <Dialog
        open={modal.isOpen}
        onClose={closeModal}
        maxWidth={modal.maxWidth || "sm"}
        scroll={"paper"}
        className={`c-dialog ${modal.classNames}`}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth>
        <DialogTitle
          id="scroll-dialog-title"
          className="c-dialog__title">
          {modal.title}
          <IconButton
            aria-label="close"
            onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {modal.contentComponent && (
          <modal.contentComponent
            {...modal}
            close={closeModal}
            setTitle={setTitle}
          />
        )}
      </Dialog>
    </DialogContext.Provider>
  );
}

export const useDialogContext = () => useContext(DialogContext);
