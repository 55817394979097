import Layout from "../components/Layout";
import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { instance } from "../routes/axios";
import { showSnackbar } from "../store/snackbarSlice";
import EditIcon from "@mui/icons-material/Edit";
import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { formatDateTime, modelDetectionTableHead } from "../components/users/helpers";
import CTableHead from "../components/table/CTableHead";
import TableBodyLoading from "../components/table/TableBodyLoading";
import CTablePagination from "../components/table/CTablePagination";
import ModelDetectionToolbar from "../components/users/ModelDetectionToolbar";
import CTableRowCheckbox from "../components/table/CTableRowCheckbox";
import { IconButton } from "rsuite";
import ConfigurationsNav from "../components/configurations/ConfigurationsNav";
import CustomDialog from "../components/libs/CustomDialog";
import ModelDetectionForm from "../components/users/ModelDetectionForm";

export default function ModelDetectionPage() {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("asc");
  const [sortBy, setSortBy] = useState("created_at");
  const [editValues, setEditValues] = useState({});
  const [openUpload, setOpenUpload] = useState(false);

  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const res = await instance.post("/fetch-all-yolo-versions", {
        orderBy,
        sortBy,
        page,
        limit,
      });
      setData(res.data.data.versions);
      setTotalCount(res.data.data.totalCount);
    } catch (err) {
      dispatch(showSnackbar({ message: "Something went wrong", severity: "error" }));
    } finally {
      setLoading(false);
    }
  }, [dispatch, page, limit, orderBy, sortBy]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function editVersion(e, row) {
    e.stopPropagation();
    setEditValues(row);
    setOpenUpload(true);
  }
  return (
    <Layout>
      <ConfigurationsNav />
      <CustomDialog
        open={openUpload}
        title="Edit version"
        close={() => setOpenUpload(false)}>
        <ModelDetectionForm
          close={() => setOpenUpload(false)}
          fetchData={fetchData}
          editValues={editValues}
          _id={editValues?._id}
          isEdit
        />
      </CustomDialog>
      <ModelDetectionToolbar
        selected={selected}
        setSelected={setSelected}
        fetchData={fetchData}
        totalCount={totalCount}
      />
      <TableContainer>
        <Table
          sx={{ minWidth: 900 }}
          aria-labelledby="tableTitle">
          <CTableHead
            setOrderBy={setOrderBy}
            setSortBy={setSortBy}
            data={data}
            setSelected={setSelected}
            headData={modelDetectionTableHead}
            orderBy={orderBy}
            sortBy={sortBy}
            numSelected={selected.length}
            rowCount={data.length}>
            <TableCell width="40px"></TableCell>
          </CTableHead>

          <TableBody>
            {isLoading ? (
              <TableBodyLoading colSpan={modelDetectionTableHead.length + 1} />
            ) : (
              data.map((row, index) => {
                const isItemSelected = selected.some((obj) => obj._id === row._id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    tabIndex={-1}
                    aria-checked={isItemSelected}
                    selected={isItemSelected}
                    key={row._id}
                    sx={{ cursor: "pointer" }}>
                    <CTableRowCheckbox
                      _id={row._id}
                      data={data}
                      labelId={labelId}
                      isItemSelected={isItemSelected}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <TableCell>{row._id}</TableCell>
                    <TableCell>{row?.version}</TableCell>
                    <TableCell>{row.actualVersionValue}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{formatDateTime(row.createdAt)}</TableCell>
                    <TableCell>{formatDateTime(row.updatedAt)}</TableCell>
                    <TableCell>
                      {row.isDefault && (
                        <div className={`drill-status processed`}>
                          <p style={{ padding: "5px" }}>{"Default"}</p>
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        icon={<EditIcon />}
                        size="sm"
                        onClick={(e) => editVersion(e, row)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CTablePagination
        setPage={setPage}
        setLimit={setLimit}
        totalCount={totalCount}
        limit={limit}
        page={page}
      />
    </Layout>
  );
}
