import Layout from "../components/Layout";
import { useEffect, useState, useCallback } from "react";
import { instance } from "../routes/axios";
import { InputGroup, Input } from "rsuite";
import useDebounce from "../helpers/useDebounce";
import AdminsTable from "../components/admins/AdminsTable";

export function UsersStatsItem({ title, value, children }) {
  return (
    <div className="usersStats__item">
      <p className="title-sm text-greyTitle">{title}</p>
      {!children && <div className="page-block-value flex items-center mb-0">{value}</div>}
      {children}
    </div>
  );
}

export default function AdminsPage() {
  const [users, setUsers] = useState([]);
  const [usersTotalCount, setUsersTotalCount] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [orderBy, setOrderBy] = useState("asc");
  const [sortBy, setSortBy] = useState("createdAt");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const fetchData = useCallback(
    async (search = "") => {
      setLoading(true);
      try {
        const res = await instance.post("/fetch-admins", { orderBy, sortBy, page, limit, search });
        setUsers(res.data?.data?.adminUsers);
        setUsersTotalCount(res.data?.data?.totalCount);
        setLoading(false);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    },
    [orderBy, sortBy, page, limit]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const debouncedFetchData = useDebounce(fetchData, 500);

  const handleSearchChange = (value) => {
    setSearch(value);
    debouncedFetchData(value);
  };

  return (
    <Layout>
      <div className="main-content-px">
        <p className="main-content-title">Admins</p>
        <div className="filters-block filters-block-users">
          <div className="filters-block-item">
            <p className="filter-label">Search</p>
            <InputGroup size="lg">
              <Input
                placeholder="Search admin by name, email..."
                onChange={(value) => handleSearchChange(value)}
              />
            </InputGroup>
          </div>
        </div>
      </div>
      <AdminsTable
        data={users}
        fetchData={fetchData}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        sortBy={sortBy}
        setSortBy={setSortBy}
        limit={limit}
        setLimit={setLimit}
        page={page}
        setPage={setPage}
        usersTotalCount={usersTotalCount}
        isLoading={isLoading}
      />
    </Layout>
  );
}
