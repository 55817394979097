import { Toolbar } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import CustomDialog from "../libs/CustomDialog";
import { Button } from "rsuite";
import SetAnalysisVersionsDefualtForm from "./SetAnalysisVersionsDefaultForm";

export default function AnalysisVersionsToolbar({ selected, fetchData }) {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [isDefault, setIsDefault] = useState(false);

  return (
    <>
      <Toolbar className="promptsTableToolbar">
        <p className="promptsTableToolbar__title">{selected.length > 0 ? `${selected.length} selected` : `${""} total`}</p>
        <div className="usersTableToolbar__btns">
          <Button
            appearance="ghost"
            onClick={() => setIsDefault(true)}
            loading={isLoading}
            className="outlined-btn">
            Set Default Version
          </Button>
        </div>
      </Toolbar>
      <CustomDialog
        open={isDefault}
        title="Set Default Version"
        close={() => setIsDefault(false)}>
        <SetAnalysisVersionsDefualtForm
          close={() => setIsDefault(false)}
          fetchData={fetchData}
        />
      </CustomDialog>
    </>
  );
}
