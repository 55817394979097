import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useState } from "react";
import { IconButton } from "rsuite";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import EditIcon from "@mui/icons-material/Edit";
import { Input, SelectPicker } from "rsuite";
import { formatDateTime, formatStringId } from "../users/helpers";
import CTableRowCheckbox from "../table/CTableRowCheckbox";
import { instance } from "../../routes/axios";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../store/snackbarSlice";

export default function PromptTableExpandRow({ data, fetchData, setSelected, colSpan, row, index, selected, handleClickSelect, editPrompt }) {
  const [open, setOpen] = useState(false);
  const isItemSelected = selected.some((obj) => obj._id === row._id);
  const labelId = `enhanced-table-checkbox-${index}`;
  const dispatch = useDispatch();
  const activeVersion = row.active_version;

  const allAvailableVersions = row.history.map((x) => {
    return {
      label: `Version ${x.version}`,
      value: x.version,
    };
  });
  const versionsData = [{ label: "Default", value: null }, ...allAvailableVersions];

  async function onChangeVersion(value) {
    if (activeVersion === value) {
      return;
    }
    try {
      const res = await instance.put(`/prompts/${row._id}/active-version/${value ? value : "default"}`);
      fetchData();
      dispatch(showSnackbar({ message: `Version of selected prompt has been changed to ${value ? value : "default"}`, severity: "success" }));
    } catch (err) {
      dispatch(showSnackbar({ message: "Something went wrong", severity: "error" }));
    }
  }

  function ContentItem({ x }) {
    return (
      <div className={`prompts__log-item ${activeVersion === x.version ? "active" : activeVersion === null && !x.version ? "active" : ""}`}>
        <p className="prompts__log-group">
          Version: <span>{x.version || "Default"}</span>
        </p>
        <p className="prompts__log-group">
          Access type: <span>{x.access_type || "-"}</span>
        </p>
        <p className="prompts__log-group">
          Specific users:{" "}
          <span>
            {x.specific_user?.map((x) => {
              return (
                <span
                  style={{ display: "block" }}
                  key={x}>
                  {x}
                </span>
              );
            })}
          </span>
        </p>
        <p className="prompts__log-group">
          Prompt type: <span>{x.prompt_type || "-"}</span>
        </p>
        <p className="prompts__log-group">
          Title: <span>{x.title || "-"}</span>
        </p>
        <p className="prompts__log-group">
          Created at: <span>{formatDateTime(x.createdAt)}</span>
        </p>
        <p className="prompts__log-group">
          Updated at: <span>{formatDateTime(x.updatedAt)}</span>
        </p>
        <p className="prompts__log-group">
          Updated by: <span>{x.updated_by || "-"}</span>
        </p>
        <br />
        <p>Content:</p>
        <Input
          size="lg"
          as="textarea"
          rows={20}
          value={x.content}
          disabled
        />
      </div>
    );
  }

  const currentItem = activeVersion ? row.history.find((x) => x.version === activeVersion) : row;

  return (
    <>
      <TableRow
        tabIndex={-1}
        aria-checked={isItemSelected}
        selected={isItemSelected}
        sx={{ cursor: "pointer" }}>
        <CTableRowCheckbox
          selected={selected}
          setSelected={setSelected}
          data={data}
          _id={row?._id}
          labelId={labelId}
          isItemSelected={isItemSelected}
        />
        <TableCell>{formatStringId(row._id)}</TableCell>
        <TableCell>{row.active_version ? row.active_version : "Default"}</TableCell>

        <TableCell>{currentItem?.access_type}</TableCell>
        <TableCell>{currentItem?.prompt_type}</TableCell>
        <TableCell>{currentItem?.title}</TableCell>
        <TableCell width="30%">
          <p className="overflow-text-lines">{currentItem?.content}</p>
        </TableCell>
        <TableCell>{formatDateTime(currentItem?.createdAt)}</TableCell>
        <TableCell>
          <IconButton
            icon={<EditIcon />}
            size="sm"
            onClick={(e) => editPrompt(e, row)}
          />
        </TableCell>
        <TableCell>
          <button
            onClick={() => {
              setOpen(!open);
            }}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </button>
        </TableCell>
      </TableRow>

      <TableRow className="expand">
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={colSpan}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit>
            <div className="prompts__log">
              <div className="prompts__log-select">
                <p>Change active version:</p>
                <SelectPicker
                  size="lg"
                  value={activeVersion}
                  data={versionsData}
                  cleanable={false}
                  searchable={false}
                  onChange={onChangeVersion}
                />
              </div>
              <p className="prompts__log-group">
                Created by: <span>{row.created_by}</span>
              </p>

              <ContentItem x={row} />
              {row.history
                .slice()
                .reverse()
                .map((x) => {
                  return (
                    <ContentItem
                      key={x._id}
                      x={x}
                    />
                  );
                })}
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
