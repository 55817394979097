import { useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatDateTime, formatStringId, usersTableHead } from "./helpers";
import UsersTableToolbar from "./UsersTableToolbar";
import CTablePagination from "../table/CTablePagination";
import TableBodyLoading from "../table/TableBodyLoading";
import CTableHead from "../table/CTableHead";
import CTableRowCheckbox from "../table/CTableRowCheckbox";

export default function UsersTable({ isLoading, usersTotalCount, setLimit, limit, setPage, page, data, fetchData, setOrderBy, orderBy, sortBy, setSortBy }) {
  const [selected, setSelected] = useState([]);
  const navigate = useNavigate();

  function handleNavigate(row) {
    navigate(`/users/${row._id}`);
  }

  return (
    <>
      <UsersTableToolbar selected={selected} fetchData={fetchData} setSelected={setSelected} />
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <CTableHead setOrderBy={setOrderBy} setSortBy={setSortBy} data={data} setSelected={setSelected} headData={usersTableHead} orderBy={orderBy} sortBy={sortBy} numSelected={selected.length} rowCount={data.length} />

          <TableBody>
            {isLoading ? (
              <TableBodyLoading colSpan={usersTableHead.length + 1} />
            ) : (
              data.map((row, index) => {
                const isItemSelected = selected.some((obj) => obj._id === row._id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row._id} selected={isItemSelected} onClick={() => handleNavigate(row)} sx={{ cursor: "pointer" }}>
                    <CTableRowCheckbox _id={row._id} data={data} labelId={labelId} isItemSelected={isItemSelected} selected={selected} setSelected={setSelected} />
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.userId}</TableCell>
                    <TableCell>{formatStringId(row._id)}</TableCell>
                    <TableCell>{formatDateTime(row.createdDate)}</TableCell>
                    <TableCell>{formatDateTime(row.updatedDate)}</TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CTablePagination setPage={setPage} setLimit={setLimit} totalCount={usersTotalCount} limit={limit} page={page} />
    </>
  );
}
