import { Button, SelectPicker } from "rsuite";
import { useForm, Controller } from "react-hook-form";
import AuthFormGroup from "../auth/AuthFormGroup";
import { useState, useEffect, useCallback, useMemo } from "react";
import { instance } from "../../routes/axios";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../store/snackbarSlice";
import { DialogContent, DialogActions } from "@mui/material";

export default function ReprocessForm({ fetchData, close, editValues }) {
  const [isLoading, setLoading] = useState(false);
  const [analysisVersionsTypes, setAnalysisVersionsTypes] = useState([]);
  const [modelDetectionTypes, setModelDetectionTypes] = useState([]);
  const [scoringMetricsTypes, setScoringMetricsTypes] = useState([]);
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { errors, isDirty, dirtyFields },
  } = useForm({
    defaultValues: useMemo(() => {
      return editValues;
    }, [editValues]),
    mode: "onChange",
  });

  const fetchVersions = useCallback(async () => {
    try {
      const res = await instance.post("/fetch-all-yolo-versions", {});
      if (res.data?.data?.versions) {
        const formattedData = res?.data?.data?.versions.map((versionObj, index) => ({
          label: `Model Version ${versionObj?.version} (${versionObj?.actualVersionValue})`,
          value: versionObj?.version,
        }));
        setModelDetectionTypes(formattedData);
      } else {
        setModelDetectionTypes([]);
      }
    } catch (err) {
      dispatch(showSnackbar({ message: "Something went wrong", severity: "error" }));
    }
  }, [dispatch]);

  const fetchScoringMetrics = useCallback(async () => {
    setLoading(true);
    try {
      const res = await instance.post("/scoring-metrics/fetch-all", {});
      if (res.data?.data?.allScoringMetrics) {
        const formattedData = res?.data?.data?.allScoringMetrics.map((versionObj) => ({
            label: `Score Version- ${versionObj?.version} (${versionObj?.name}) ${versionObj?.isDefault ? " - Default" : ""}`,
            value: versionObj?.version,
          })
        );
        setScoringMetricsTypes(formattedData);
      } else {
        setScoringMetricsTypes([]);
      }
    } catch (err) {
      dispatch(showSnackbar({ message: "Failed to fetch Scoring metrics", severity: "error" }));
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  const fetchAnalysisVersions = useCallback(async () => {
    try {
      const res = await instance.get("/drill-analysis/versions", {});
      if (res.data?.data) {
        const formattedData = res?.data?.data?.map((x) => {
          return {
            label: `${x?.title} (${x?.version})`,
            value: x?.version,
          };
        });
        setAnalysisVersionsTypes(formattedData);
      } else {
        setAnalysisVersionsTypes([]);
      }
    } catch (err) {
      dispatch(showSnackbar({ message: "Something went wrong", severity: "error" }));
    }
  }, [dispatch]);

  useEffect(() => {
    fetchVersions();
    fetchAnalysisVersions();
    fetchScoringMetrics();
  }, [fetchVersions, fetchAnalysisVersions, fetchScoringMetrics]);

  async function onSubmit(data) {
    setLoading(true);
    try {
      await instance.post(`/drills/${editValues._id}/reprocess`, { ...data });
      fetchData();
      close();
      dispatch(showSnackbar({ message: "Reprocess..", severity: "success" }));
    } catch (err) {
      dispatch(showSnackbar({ message: err.response.data.message || "Something went wrong", severity: "error" }));
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <DialogContent className="c-dialog__content" dividers>
        <div className="form prompts__form" id="scroll-dialog-description">
          <div className="reprocessForm__selected">
            <p>
              Model Detection version {editValues.model_detection_version} {"->"} <strong className={`${dirtyFields.model_detection_version ? "active" : ""}`}>{watch("model_detection_version")}</strong>
              <br />
              Analysis Script version {editValues.analysis_version} {"->"} <strong className={`${dirtyFields.analysis_version ? "active" : ""}`}>{watch("analysis_version")}</strong>
              <br />
              Scoring Metrics version {editValues.scoring_metrics_version} {"->"} <strong className={`${dirtyFields.scoring_metrics_version ? "active" : ""}`}>{watch("scoring_metrics_version")}</strong>
            </p>
          </div>
          <div className="form__fields">
            <AuthFormGroup label="Model Detection Version" error={errors.model_detection_version}>
              <Controller name="model_detection_version" control={control} render={({ field }) => <SelectPicker size="lg" cleanable={false} id={field.name} value={field.value} data={modelDetectionTypes} searchable={false} onChange={field.onChange} placeholder="Select Model Detection Version" />} />
            </AuthFormGroup>
            <AuthFormGroup label="Analysis Script version" error={errors.analysis_version}>
              <Controller name="analysis_version" control={control} render={({ field }) => <SelectPicker size="lg" id={field.name} cleanable={false} value={field.value} data={analysisVersionsTypes} searchable={false} onChange={field.onChange} placeholder="Select Analysis Script Version" />} />
            </AuthFormGroup>
            <AuthFormGroup label="Scoring Metrics Version" error={errors.scoring_metrics_version}>
              <Controller name="scoring_metrics_version" control={control} render={({ field }) => <SelectPicker size="lg" id={field.name} value={field.value} cleanable={false} data={scoringMetricsTypes} searchable={false} onChange={field.onChange} placeholder="Select Scoring Metric Version" menuStyle={{maxHeight: '150px', overflowY: 'auto'}} />} />
            </AuthFormGroup>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="c-dialog__btns">
          <Button appearance="ghost" onClick={close} className="outlined-btn">
            Cancel
          </Button>
          <Button
            appearance="primary"
            // disabled={!isDirty}
            onClick={handleSubmit(onSubmit)}
            loading={isLoading}
            className="outlined-btn black-btn min-width">
            Submit
          </Button>
        </div>
      </DialogActions>
    </>
  );
}
