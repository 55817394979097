import { useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { instance } from "../routes/axios";
import { showSnackbar } from "../store/snackbarSlice";
import Layout from "../components/Layout";
import { InputGroup, Input, Button } from "rsuite";
import useDebounce from "../helpers/useDebounce";
import { useNavigate } from "react-router-dom";
import { formatDateTime } from "../components/users/helpers";
import CTablePagination from "../components/table/CTablePagination";
import TableBodyLoading from "../components/table/TableBodyLoading";
import { allDrillsTableHead } from "../components/users/helpers";
import CTableHead from "../components/table/CTableHead";
import ManageVideosTableToolbar from "../components/manage-videos/ManageVideosTableToolbar";
import CTableRowCheckbox from "../components/table/CTableRowCheckbox";
import TableCellEmail from "../components/table/TableCellEmail";
import TableCellName from "../components/table/TableCellName";
import CustomDialog from "../components/libs/CustomDialog";
import ReprocessForm from "../components/manage-videos/ReprocessForm";
import ManageVideosFilters from "../components/manage-videos/ManageVideosFilters";

export default function ManageVideosPage() {
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);
  const [orderBy, setOrderBy] = useState("desc");
  const [sortBy, setSortBy] = useState("uploadDate");
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filteredCount, setFilteredCount] = useState(0);
  const [selected, setSelected] = useState([]);
  const [reprocessDialog, setReprocessDialog] = useState(false);
  const [editValues, setEditValues] = useState(null);
  const [searchUser, setSearch] = useState("");
  const [modelValues, setModelValues] = useState([]);
  const [analysisValues, setAnalysisValues] = useState([]);
  const [statusValues, setStatusValues] = useState([]);
  const isFilterApplied = useRef(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const res = await instance.post("/fetch-drills", {
        page: page,
        limit,
        search: {
          userId: searchUser,
          status: statusValues,
          analysis_version: analysisValues,
          model_detection_version: modelValues,
        },
        orderBy,
        sortBy,
      });
      setData(res?.data?.data?.videoUpload);
      setTotalCount(res?.data?.data?.totalCount);
      setFilteredCount(res?.data?.data?.filteredCount);
      if (isFilterApplied.current && page !== 1) {
        return;
      }
    } catch (err) {
      console.error(err);
      dispatch(showSnackbar({ message: "Something went wrong", severity: "error" }));
    } finally {
      setLoading(false);
    }
  }, [page, limit, searchUser, statusValues, analysisValues, modelValues, orderBy, sortBy, dispatch]);

  const debouncedFetchData = useDebounce(fetchData, 500);

  useEffect(() => {
    if (isFilterApplied.current) {
      setPage(1);
    }
    isFilterApplied.current = true;
  }, [searchUser, orderBy, sortBy, limit, modelValues, analysisValues, statusValues]);

  useEffect(() => {
    if (searchUser) {
      debouncedFetchData();
    } else {
      fetchData();
    }
  }, [searchUser, page, orderBy, sortBy, limit, modelValues, analysisValues, statusValues, fetchData, debouncedFetchData]);

  const handleSearchChange = (value) => {
    setSearch(value);
  };

  function handleNavigate(row) {
    navigate(`/users/${row.user}/drill-history/${row._id}`);
  }

  function openReprocessDialog(e, row) {
    e.stopPropagation();
    setReprocessDialog(true);
    setEditValues({
      analysis_version: row.analysis_version || "v1.0.0",
      model_detection_version: row.model_detection_version || 1,
      scoring_metrics_version: row.scoring_metrics_version || 1,
      _id: row._id,
    });
  }

  return (
    <Layout>
      <div className="main-content-px">
        <p className="main-content-title">Manage videos</p>
        <div className="filters-block filters-block-videos">
          <div className="filters-block-item">
            <p className="filter-label">Search</p>
            <InputGroup size="lg">
              <Input
                placeholder="Search Video by User..."
                onChange={(value) => handleSearchChange(value)}
              />
            </InputGroup>
          </div>
          <ManageVideosFilters
            setModelValues={setModelValues}
            setAnalysisValues={setAnalysisValues}
            setStatusValues={setStatusValues}
            fetchData={fetchData}
          />
        </div>
      </div>
      <ManageVideosTableToolbar
        selected={selected}
        fetchData={fetchData}
        setSelected={setSelected}
        totalCount={totalCount}
      />
      <TableContainer>
        <Table sx={{ minWidth: 900 }}>
          <CTableHead
            setOrderBy={setOrderBy}
            setSortBy={setSortBy}
            data={data}
            headData={allDrillsTableHead}
            orderBy={orderBy}
            sortBy={sortBy}
            rowCount={data.length}
            setSelected={setSelected}
            numSelected={selected.length}>
            <TableCell width="100"></TableCell>
          </CTableHead>
          <TableBody>
            {isLoading ? (
              <TableBodyLoading colSpan={allDrillsTableHead.length + 1} />
            ) : (
              data.map((row, index) => {
                const isItemSelected = selected.some((obj) => obj._id === row._id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    key={row._id}
                    onClick={() => handleNavigate(row)}
                    role="checkbox"
                    tabIndex={-1}
                    hover
                    style={{ cursor: "pointer" }}>
                    <CTableRowCheckbox
                      _id={row._id}
                      data={data}
                      labelId={labelId}
                      isItemSelected={isItemSelected}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <TableCellName name={row.gameType} />
                    <TableCell>{row.model_detection_version || "N/A"}</TableCell>
                    <TableCell>{row.analysis_version || "N/A"}</TableCell>
                    <TableCell>{row.scoring_metrics_version || "N/A"}</TableCell>
                    <TableCell>{formatDateTime(row.uploadDate)}</TableCell>
                    <TableCell>
                      <p className={`drill-status ${row.status.toLowerCase()}`}>{row.status}</p>
                    </TableCell>
                    <TableCellEmail email={row.userId} />
                    <TableCell>
                      <Button
                        appearance="ghost"
                        onClick={(e) => openReprocessDialog(e, row)}
                        className="outlined-btn black-outlined-btn">
                        Re-process
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomDialog
        open={reprocessDialog}
        title="Re-process"
        close={() => setReprocessDialog(false)}>
        <ReprocessForm
          close={() => setReprocessDialog(false)}
          editValues={editValues}
          fetchData={fetchData}
        />
      </CustomDialog>
      <CTablePagination
        setPage={setPage}
        setLimit={setLimit}
        totalCount={filteredCount}
        limit={limit}
        page={page}
      />
    </Layout>
  );
}
