import { useForm, Controller } from "react-hook-form";
import { useState, useMemo } from "react";
import { DialogActions, DialogContent } from "@mui/material";
import { Input, Button } from "rsuite";
import AuthFormGroup from "../auth/AuthFormGroup";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../store/snackbarSlice";
import { instance } from "../../routes/axios";

export default function ModelDetectionForm({ close, editValues, isEdit, fetchData }) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return editValues ? editValues : { version: "", actualVersionValue: "", description: "" };
    }, [editValues]),
    mode: "onChange",
  });

  async function onSubmit(data) {
    setIsLoading(true);
    try {
      const payload = isEdit
        ? { ...editValues, ...data }
        : {
            version: Number(data?.version),
            description: data?.description,
            actualVersionValue: data?.actualVersionValue,
            model_file_path: data?.model_file_path,
            model: data?.model,
            objects: {
              ball: {
                index: Number(data?.objects?.ball?.index),
                confidence_score: Number(data?.objects?.ball?.confidence_score),
              },
            },
          };
      await instance.post(isEdit ? `/update-yolo-version/${editValues._id}` : "/add-yolo-version", payload);
      fetchData();
      close();
      dispatch(
        showSnackbar({
          message: `Version has been successfully ${isEdit ? "updated" : "added"}`,
          severity: "success",
        })
      );
    } catch (err) {
      dispatch(showSnackbar({ message: err?.response?.data?.message || "Something went wrong", severity: "error" }));
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <DialogContent
        dividers
        className="c-dialog__content">
        <div
          className="form scoring__form prompts__form"
          id="scroll-dialog-description">
          <div className="form__fields">
            <AuthFormGroup
              error={errors.version}
              label="Version">
              <Controller
                control={control}
                name="version"
                rules={{
                  required: !isEdit,
                }}
                render={({ field }) => (
                  <Input
                    size="lg"
                    disabled={isEdit}
                    type={"number"}
                    id={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    placeholder="Enter Model Detection Version (Ex: 1, 2, 3...)"
                  />
                )}
              />
            </AuthFormGroup>
            <AuthFormGroup
              error={errors.actualVersionValue}
              label="Actual Version Value">
              <Controller
                control={control}
                name="actualVersionValue"
                rules={{
                  required: !isEdit,
                }}
                render={({ field }) => (
                  <Input
                    size="lg"
                    disabled={isEdit}
                    type="string"
                    id={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    placeholder="Enter Actual Version Value (Ex: 1.0.0, 1.1.0 ...)"
                  />   
                )}
              />
            </AuthFormGroup>
            <AuthFormGroup
              error={errors.model_file_path}
              label="Model File Path">
              <Controller
                control={control}
                name="model_file_path"
                rules={{
                  required: !isEdit,
                }}
                render={({ field }) => (
                  <Input
                    size="lg"
                    disabled={isEdit}
                    type="string"
                    id={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    placeholder="Enter Model File Path "
                  />   
                )}
              />
            </AuthFormGroup>
            <AuthFormGroup
              error={errors.model}
              label="Model">
              <Controller
                control={control}
                name="model"
                rules={{
                  required: !isEdit,
                }}
                render={({ field }) => (
                  <Input
                    size="lg"
                    disabled={isEdit}
                    type="string"
                    id={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    placeholder="Enter Model Name"
                  />   
                )}
              />
            </AuthFormGroup>
            <AuthFormGroup error={errors.objects} label="Objects">
              <div style={{ paddingLeft: "25px" }}>
              <AuthFormGroup error={errors.objects?.ball} label="Ball">
                <div style={{ display: "flex",justifyContent: "space-between", gap: "16px", paddingLeft: "25px"}}>
                  <AuthFormGroup error={errors.objects?.ball?.index} label="Index">
                    <Controller
                      control={control}
                      name="objects.ball.index"
                      rules={{
                        required: !isEdit,
                      }}
                      render={({ field }) => (
                        <Input
                          size="lg"
                          disabled={isEdit}
                          type="number"
                          id={field.name}
                          value={field.value || ""}
                          onChange={field.onChange}
                          placeholder="Enter Index Value"
                        />
                      )}
                    />
                  </AuthFormGroup>
                  <AuthFormGroup error={errors.objects?.ball?.confidence_score} label="Confidence Score">
                    <Controller
                      control={control}
                      name="objects.ball.confidence_score"
                      rules={{
                        required: !isEdit,
                      }}
                      render={({ field }) => (
                        <Input
                          size="lg"
                          disabled={isEdit}
                          type="number"
                          step="0.01"
                          id={field.name}
                          value={field.value || ""}
                          onChange={field.onChange}
                          placeholder="Enter Confidence Score"
                        />
                      )}
                    />
                  </AuthFormGroup>
                </div>
              </AuthFormGroup>
              </div>
            </AuthFormGroup>
            <AuthFormGroup
              label="Description"
              error={errors.description}>
              <Controller
                control={control}
                name="description"
                rules={{
                  required: true,
                  validate: (val) => {
                    if (val.length < 10 || val.length > 500) {
                      return "* Description must be between 10 and 500 characters";
                    }
                    return true;
                  },
                }}
                render={({ field }) => (
                  <Input
                    as="textarea"
                    size="lg"
                    id={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    rows={3}
                    placeholder="Enter Version Description Here"
                  />
                )}
              />
            </AuthFormGroup>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          appearance="ghost"
          onClick={close}
          className="outlined-btn">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
          appearance="primary"
          className="outlined-btn black-btn min-width">
          {isEdit ? "Update Version" : "Add Version"}
        </Button>
      </DialogActions>
    </>
  );
}
