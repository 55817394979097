import MultipleAutocomplete from "../libs/MultipleAutocomplete";
import { game_types, statusOptions } from "../users/helpers";

export default function DrillHistoryFilters({ setTypesValues, setStatusValues }) {
  return (
    <>
      <MultipleAutocomplete
        options={game_types}
        label="Drill type"
        onChange={(newValues) => setTypesValues(newValues)}
      />
      <MultipleAutocomplete
        options={statusOptions}
        label="Status"
        onChange={(newValues) => setStatusValues(newValues)}
      />
    </>
  );
}
