import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useState } from "react";
import { IconButton } from "rsuite";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import EditIcon from "@mui/icons-material/Edit";
import { formatStringId } from "../users/helpers";
import CTableRowCheckbox from "../table/CTableRowCheckbox";
import RolesAccordion from "./RolesAccordion";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export default function MetricsTableExpandRow({ data, setSelected, colSpan, row, index, selected, handleClickSelect, editPrompt }) {
  const [open, setOpen] = useState(false);
  const isItemSelected = selected.some((obj) => obj._id === row._id);
  const labelId = `enhanced-table-checkbox-${index}`;

  return (
    <>
      <TableRow
        tabIndex={-1}
        aria-checked={isItemSelected}
        selected={isItemSelected}
        sx={{ cursor: "pointer" }}>
        <CTableRowCheckbox
          selected={selected}
          setSelected={setSelected}
          data={data}
          _id={row._id}
          labelId={labelId}
          isItemSelected={isItemSelected}
        />
        <TableCell style={{ width: "100px" }}>{formatStringId(row._id)}</TableCell>
        <TableCell>{row?.version}</TableCell>
        <TableCell>{row.gameType || ""}</TableCell>
        <TableCell>{row.name || ""}</TableCell>
        <TableCell>
          {row.isDefault && (
            <div className={`drill-status processed`}>
              <p style={{ padding: "5px" }}>{"Default"}</p>
            </div>
          )}
        </TableCell>
        <TableCell>
          <IconButton
            icon={<ContentCopyIcon />}
            size="sm"
            onClick={(e) => editPrompt(e, row, true)}
          />
        </TableCell>
        <TableCell>
          <IconButton
            icon={<EditIcon />}
            size="sm"
            onClick={(e) => editPrompt(e, row)}
          />
        </TableCell>
        <TableCell>
          <button
            onClick={() => {
              setOpen(!open);
            }}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </button>
        </TableCell>
      </TableRow>

      <TableRow className="expand">
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={colSpan}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit>
            <div className="metrics__expand main-content-px">
              {Object.entries(row.rules)
                .filter(([key]) => key !== "_id")
                .map(([key, value]) => (
                  <RolesAccordion
                    key={key}
                    title={key}
                    value={value}
                  />
                ))}
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
