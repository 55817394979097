import AddIcon from "@mui/icons-material/Add";
import { Button, SelectPicker, Input } from "rsuite";
import { useCallback, useEffect, useState } from "react";
import CustomDialog from "../libs/CustomDialog";
import { instance } from "../../routes/axios";
import { showSnackbar } from "../../store/snackbarSlice";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Uploader } from "rsuite";
import AuthFormGroup from "../auth/AuthFormGroup";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Checkbox, DialogActions, DialogContent, FormControlLabel, Typography } from "@mui/material";
import { game_types } from "./helpers";

export default function DrillsHistoryUpload({ fetchData, editValues }) {
  const [openUpload, setOpenUpload] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [analysisVersionsTypes, setAnalysisVersionsTypes] = useState([]);
  const [modelDetectionTypes, setModelDetectionTypes] = useState([]);
  const [scoringMetricsTypes, setScoringMetricsTypes] = useState([]);
  const [compatibleVersion, setCompatibleVersion] = useState(null);
  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    trigger,
    watch,
    formState: { errors, dirtyFields },
  } = useForm();

  const fetchVersions = useCallback(async () => {
    setLoading(true);
    try {
      const res = await instance.post("/fetch-all-yolo-versions", {});
      if (res.data?.data?.versions) {
        const formattedData = res?.data?.data?.versions.map((versionObj, index) => ({
            label: `Model Version ${versionObj?.version} (${versionObj?.actualVersionValue})  ${versionObj?.isDefault ? "Default" : ""}`,
            value: versionObj?.version,
            _id: versionObj?._id,
          })
        );
        setModelDetectionTypes(formattedData);
      } else {
        setModelDetectionTypes([]);
      }
    } catch (err) {
      dispatch(showSnackbar({ message: "Failed to fetch model versions", severity: "error",}));
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  const fetchScoringMetrics = useCallback(async () => {
    setLoading(true);
    try {
      const res = await instance.post("/scoring-metrics/fetch-all", {});
      if (res.data?.data?.allScoringMetrics) {
        const formattedData = res?.data?.data?.allScoringMetrics.map((versionObj) => ({
            label: `Score Version- ${versionObj?.version} (${versionObj?.name}) ${versionObj?.isDefault ? " - Default" : ""}`,
            value: versionObj?.version,
          })
        );
        setScoringMetricsTypes(formattedData);
      } else {
        setScoringMetricsTypes([]);
      }
    } catch (err) {
      dispatch(showSnackbar({ message: "Failed to fetch Scoring metrics", severity: "error" }));
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  const fetchAnalysisVersions = useCallback(async () => {
    setLoading(true);
    try {
      const res = await instance.get("/drill-analysis/versions", {});
      if (res?.data?.data) {
        const formattedData = res?.data?.data?.map(({ title, version, enabled, isDefault }) => {
            return {
              label: `${title} (${version}) ${isDefault ? " - Default" : ""}`,
              value: version,
              disabled: !enabled,
            };
          });
        setAnalysisVersionsTypes(formattedData);
      } else {
        setAnalysisVersionsTypes([]);
      }
    } catch (err) {
      dispatch(showSnackbar({ message: "Failed to fetch analysis versions",severity: "error",}));
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  const fetchCompatibleAnalysisVersion = async (yoloVersionId) => {
    if (!yoloVersionId) return;
    try {
      const res = await instance.get(`/yolo-version/${yoloVersionId}/analysis-version`);
      const version = res?.data?.data?.version;
      if (version) {
        setCompatibleVersion(version);
        setValue("analysis_version", version);
        trigger("analysis_version");
      }
    } catch {
      dispatch(
        showSnackbar({
          message: "Failed to fetch compatible analysis version",
          severity: "error",
        })
      );
    }
  };

  const handleYoloVersionChange = async (selectedValue) => {
    setValue("model_detection_version", selectedValue);
    trigger("model_detection_version");

    const selectedVersion = modelDetectionTypes.find(
      (item) => item.value === selectedValue
    );
    if (selectedVersion) {
      await fetchCompatibleAnalysisVersion(selectedVersion._id);
    }
  };

  useEffect(() => {
    fetchVersions();
    fetchAnalysisVersions();
    fetchScoringMetrics();
  }, [fetchVersions, fetchAnalysisVersions, fetchScoringMetrics]);

  async function onSubmit(data) {
    const changedData = Object.keys(dirtyFields).reduce((acc, key) => {
      acc[key] = data[key];
      return acc;
    }, {});
    delete changedData.file;
    delete changedData.game_type;

    setLoading(true);

    try {
      const preSignedUrl = await instance.post(`/generate-signed-url`, {
        fileName: data?.file[0]?.name,
        actionType: "write",
        fileType: "drillsVideo",
      });
      const url = preSignedUrl?.data?.data[0];
      const blob = new Blob([data?.file[0]?.blobFile], { type: data?.file[0]?.blobFile?.type });
      await axios.put(url, blob);
      await instance.post(`/auth/app-user/${id}/drills`, {
        fileName: data?.file[0]?.name,
        type: "video",
        gameType: data?.game_type,
        contentType: "video/mp4", // "video/mp4" or "image/png"
        model_detection_version: data?.model_detection_version,
        analysis_version: data?.analysis_version,
        scoring_metrics_version: data?.scoring_metrics_version,
        generate_csv_only: data?.generate_csv_only,
        ...changedData,
      });

      fetchData();
      reset();
      setCompatibleVersion(null);
      setOpenUpload(false);
      dispatch(showSnackbar({ message: "New drill has been successfully uploaded", severity: "success" }));
    } catch (err) {
      dispatch(showSnackbar({ message: err.message || err.response.data.message || "Something went wrong", severity: "error" }));
    } finally {
      setLoading(false);
    }
  }

  const fileWatch = watch("file");

  return (
    <div className="filters-block-actions">
      <Button
        appearance="primary"
        onClick={() => setOpenUpload(true)}
        startIcon={<AddIcon />}
        className="outlined-btn black-btn">
        Upload drill
      </Button>
      <CustomDialog
        open={openUpload}
        title="Upload drill"
        close={() => {
          reset();
          setOpenUpload(false);
        }}>
        <DialogContent
          className="c-dialog__content"
          dividers>
          <div
            id="scroll-dialog-description"
            className="form prompts__form">
            <div className="form__fields">
              <AuthFormGroup error={errors.file}>
                <Controller
                  name="file"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <Uploader
                      action=""
                      disabled={fileWatch && fileWatch.length >= 1}
                      id={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      autoUpload={false}
                      multiple={false}
                      draggable>
                      <div style={{ height: 50, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <span>Click or Drag files to this area to upload</span>
                      </div>
                    </Uploader>
                  )}
                />
              </AuthFormGroup>
              <AuthFormGroup
                label="Drill type"
                error={errors.game_type}>
                <Controller
                  name="game_type"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <SelectPicker
                      size="lg"
                      id={field.name}
                      value={field.value}
                      data={game_types}
                      searchable={false}
                      onChange={field.onChange}
                      placeholder="Select drill type"
                    />
                  )}
                />
              </AuthFormGroup>
              <AuthFormGroup
                label="Title (optional)"
                error={errors.title}>
                <Controller
                  name="title"
                  control={control}
                  rules={{
                    validate: (val) => {
                      if (val && (val.length < 3 || val.length > 50)) {
                        return "* Title must be between 3 and 50 characters";
                      }
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      size="lg"
                      id={field.name}
                      onChange={field.onChange}
                    />
                  )}
                />
              </AuthFormGroup>
              <AuthFormGroup label="Model Detection Version (optional)" error={errors.model_detection_version}>
                <Controller name="model_detection_version" control={control} render={({ field }) => <SelectPicker size="lg" id={field.name} value={field.value} data={modelDetectionTypes} searchable={false} onChange={(value) => handleYoloVersionChange(value)} placeholder="Select Model Detection Version" />} />
              </AuthFormGroup>
              {watch("model_detection_version") && watch("analysis_version") === compatibleVersion && (
              <Typography variant="body2" sx={{ color: "#388E3C", fontWeight: 600, marginTop: "8px" }}>
                For Model Detection Version <strong>{watch("model_detection_version")}</strong>,  
                Compatible Analysis Version is <strong> {watch("analysis_version")}</strong>.
              </Typography>
              )}
              <AuthFormGroup label="Analysis Script version (optional)" error={errors.analysis_version}>
                <Controller
                  name="analysis_version"
                  control={control}
                  render={({ field }) => (
                    <SelectPicker
                      size="lg"
                      id={field.name}
                      value={field.value || compatibleVersion}
                      data={analysisVersionsTypes}
                      searchable={false}
                      onChange={(value, item) => {
                        if (!item || item.disabled) return;
                        if (value === compatibleVersion) {
                          setCompatibleVersion(value);
                        }
                        setValue("analysis_version", value);
                      }}
                      placeholder="Select Analysis Script Version"
                      menuStyle={{maxHeight: '150px', overflowY: 'auto'}}
                      renderMenuItem={(label, item) => (
                        <div
                          style={{ color: item.disabled ? "#ccc" : "inherit",
                            cursor: item.disabled ? "not-allowed" : "pointer",
                           }}
                          onClick={(e) => {
                            if (item.disabled) {
                              e.preventDefault();
                              e.stopPropagation();
                            }
                          }}
                        >
                          {label}
                        </div>
                      )}
                    />
                  )}
                />
              </AuthFormGroup>
              <AuthFormGroup
                label="Scoring Metrics Version (optional)"
                error={errors.scoring_metrics_version}>
                <Controller
                  name="scoring_metrics_version"
                  control={control}
                  render={({ field }) => (
                    <SelectPicker
                      size="lg"
                      id={field.name}
                      value={field.value}
                      data={scoringMetricsTypes}
                      searchable={false}
                      onChange={field.onChange}
                      placeholder="Select Scoring Metric Version"
                      menuStyle={{maxHeight: '150px', overflowY: 'auto'}}
                    />
                  )}
                />
              </AuthFormGroup>
              <AuthFormGroup
                label="Description (optional)"
                error={errors.description}>
                <Controller
                  name="description"
                  control={control}
                  rules={{
                    validate: (val) => {
                      if (val && (val.length < 10 || val.length > 500)) {
                        return "* Description must be between 10 and 500 characters";
                      }
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      size="lg"
                      id={field?.name}
                      onChange={field.onChange}
                    />
                  )}
                />
              </AuthFormGroup>
              <AuthFormGroup>
                <Controller
                  name="generate_csv_only"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field?.value}
                          sx={{ transform: "scale(0.8)" }}
                        />
                      }
                      label={<Typography variant="body2">Generate CSV Only</Typography>}
                    />
                  )}
                />
              </AuthFormGroup>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="c-dialog__btns">
            <Button
              appearance="ghost"
              onClick={() => {
                reset();
                setOpenUpload(false);
                setCompatibleVersion(null);
              }}
              className="outlined-btn">
              Cancel
            </Button>
            <Button
              appearance="primary"
              onClick={handleSubmit(onSubmit)}
              loading={isLoading}
              className="outlined-btn black-btn min-width">
              Upload
            </Button>
          </div>
        </DialogActions>
      </CustomDialog>
    </div>
  );
}
