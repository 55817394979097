import { NavLink } from "react-router-dom";

export default function ConfigurationsNav() {
  const baseUrl = "/configurations";

  return (
    <>
      <div className="main-content-px">
        <p className="main-content-title">AIM Configurations</p>
      </div>
      <div className="userNav">
        <div className="userNav__row main-content-px">
          <NavLink
            to={baseUrl}
            end>
            Overview
          </NavLink>
          <NavLink to={baseUrl + "/scoring-metrics"}>Scoring metrics</NavLink>
          <NavLink to={baseUrl + "/prompt-management"}>Prompt management</NavLink>
          <NavLink to={baseUrl + "/model-detection"}>Model Detection</NavLink>
          <NavLink to={baseUrl + "/analysis-versions"}>Analysis Versions</NavLink>
        </div>
      </div>
    </>
  );
}
