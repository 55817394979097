import { NavLink, useNavigate } from "react-router-dom";
import { SpaceDashboard, Logout, SupervisedUserCircle, ManageAccounts, Settings, DisplaySettings } from "@mui/icons-material";
import { openedMixin, closedMixin } from "../helpers/sidebar";
import { styled, useTheme } from "@mui/material/styles";
import { useMediaQuery, Drawer as MuiDrawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import VideocamIcon from "@mui/icons-material/Videocam";
import { logout } from "../routes/axios";

const drawerWidth = 240;

const menuItems = [
  { text: "Dashboard", to: "/dashboard", icon: <SpaceDashboard /> },
  { text: "Users", to: "/users", icon: <SupervisedUserCircle /> },
  { text: "Configurations", to: "/configurations", icon: <DisplaySettings /> },
  { text: "Admins", to: "/admins", icon: <ManageAccounts /> },
  { text: "Settings", to: "/settings", icon: <Settings /> },
  { text: "Videos", to: "/manage-videos", icon: <VideocamIcon /> },
];

const DesktopDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme, drawerWidth),
        "& .MuiDrawer-paper": openedMixin(theme, drawerWidth),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      },
    },
    {
      style: {
        "& .MuiButtonBase-root ": {
          paddingTop: 0,
          paddingBottom: 0,
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
        "& .MuiList-root": {
          paddingTop: "16px",
          borderTopLeftRadius: "8px",
        },
      },
    },
  ],
}));

export default function Sidebar({ open, close }) {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  function DrawerContent() {
    return (
      <List className="sidebar__list">
        {isTablet && (
          <div className="sidebar__m-header">
            <div className="header__user">
              <div className="header__user-icon">PM</div>
            </div>
            <button onClick={close}>
              <ClearIcon />
            </button>
          </div>
        )}
        {menuItems.map((item, index) => (
          <ListItem
            key={item.text}
            disablePadding
            sx={{ display: "block" }}>
            <ListItemButton
              onClick={isTablet ? close : undefined}
              sx={[
                {
                  minHeight: 44,
                  px: "16px",
                },
              ]}>
              <NavLink
                className="sidebar__link"
                to={item.to}>
                <ListItemIcon
                  className="sidebar__icon"
                  sx={[
                    {
                      minWidth: 0,
                    },
                    open
                      ? {
                          mr: "4px",
                        }
                      : {
                          mr: "auto",
                        },
                  ]}>
                  {item.icon}
                </ListItemIcon>
                <p className={`sidebar__text title-sm ${open ? "active" : ""}`}>{item.text}</p>
              </NavLink>
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem
          disablePadding
          sx={{ display: "flex", flexGrow: 1, alignItems: "flex-end" }}>
          <ListItemButton
            sx={[
              {
                minHeight: 44,
                px: "16px",
              },
            ]}>
            <button
              className="sidebar__link"
              onClick={logout}>
              <ListItemIcon
                className="sidebar__icon"
                sx={[
                  {
                    minWidth: 0,
                  },
                  open
                    ? {
                        mr: "4px",
                      }
                    : {
                        mr: "auto",
                      },
                ]}>
                <Logout />
              </ListItemIcon>
              <p className={`sidebar__text title-sm ${open ? "active" : ""}`}>Logout</p>
            </button>
          </ListItemButton>
        </ListItem>
      </List>
    );
  }

  return (
    <div>
      {isTablet ? (
        <MuiDrawer
          variant="temporary"
          open={open}
          onClose={close}
          className="sidebar"
          ModalProps={{
            keepMounted: true,
          }}>
          <DrawerContent />
        </MuiDrawer>
      ) : (
        <DesktopDrawer
          variant="permanent"
          className="sidebar"
          open={open}>
          <DrawerContent />
        </DesktopDrawer>
      )}
    </div>
  );
}
