import moment from "moment";

const id_width = "140px";
const date_width = "200px";
const email_width = "100px";
const prompt_type_width = "150px";
const status_width = "200px";

export const usersTableHead = [
  { id: "name", numeric: false, sortable: true, label: "Full Name" },
  { id: "userId", numeric: false, sortable: true, label: "User Email", width: email_width },
  { id: "userDetails", numeric: false, sortable: true, label: "User Id", width: id_width },
  { id: "createdDate", numeric: true, sortable: true, label: "Created date", width: date_width },
  { id: "updatedDate", numeric: false, sortable: true, label: "Updated date", width: date_width },
];

export const adminsTableHead = [
  { id: "firstName", numeric: false, sortable: true, label: "Full Name" },
  { id: "email", numeric: false, sortable: true, label: "Email", width: email_width },
  { id: "role", numeric: false, sortable: true, label: "Role", width: date_width },
  { id: "createdDate", numeric: true, sortable: true, label: "Created date", width: date_width },
  { id: "updatedDate", numeric: false, sortable: true, label: "Updated date", width: date_width },
];

export const promptTableHead = [
  { id: "_id", numeric: false, sortable: false, label: "Prompt ID", width: id_width },
  { id: "version", numeric: true, sortable: false, label: "Version", width: id_width },
  { id: "access_type", numeric: false, sortable: true, label: "Access type", width: prompt_type_width },
  { id: "prompt_type", numeric: false, sortable: true, label: "Prompt type", width: prompt_type_width },
  { id: "title", numeric: false, sortable: true, label: "Title", width: "15%" },
  { id: "description", numeric: false, sortable: false, label: "Content", width: "35%" },
  { id: "created_at", numeric: false, sortable: true, label: "Date", width: date_width },
];

export const allDrillsTableHead = [
  { id: "gameType", numeric: false, sortable: true, label: "Name" },
  { id: "model_detection_version", numeric: false, sortable: true, label: "Detection <br/>Version", width: "10%" },
  { id: "analysis_version", numeric: false, sortable: true, label: "Analysis <br/>Version", width: "10%" },
  { id: "scoring_metrics_version", numeric: false, sortable: true, label: "Metrics <br/>Version", width: "10%" },
  { id: "uploadDate", numeric: false, sortable: true, label: "Uploaded Date", width: date_width },
  { id: "status", numeric: false, sortable: true, label: "Status", width: status_width },
  { id: "userId", numeric: false, sortable: true, label: "By User", width: status_width },
];

export const metricsTableHead = [
  { id: "_id", numeric: false, sortable: true, label: "Prompt ID", width: id_width },
  { id: "version", numeric: true, sortable: true, label: "Version", width: id_width },
  { id: "gameType", numeric: false, sortable: true, label: "Game type" },
  { id: "name", numeric: false, sortable: true, label: "Name" },
];

export const modelDetectionTableHead = [
  { id: "_id", numeric: false, sortable: true, label: "Version ID", width: id_width },
  { id: "version", numeric: true, sortable: true, label: "Version" },
  { id: "actualVersionValue", numeric: true, sortable: true, label: "Version Value" },
  { id: "description", numeric: false, sortable: false, label: "Description", width: "20%" },
  { id: "createdAt", numeric: false, sortable: true, label: "Created At" },
  { id: "updatedAt", numeric: false, sortable: true, label: "Updated At" },
];

export const analysisVersionTableHead = [
  { id: "_id", numeric: false, sortable: false, label: "Version ID", width: id_width },
  { id: "title", numeric: false, sortable: false, label: "Version" },
  { id: "version", numeric: true, sortable: false, label: "Version Value" },
  { id: "createdAt", numeric: false, sortable: false, label: "Created At" },
  { id: "updatedAt", numeric: false, sortable: false, label: "Updated At" },
  { id: "enabled", numeric: false, sortable: false, label: "Enable/Disable" },

];

export const drillHistoryTableHead = [
  { id: "name", numeric: false, sortable: false, label: "Name", width: "200px" },
  { id: "model_detection_version", numeric: false, sortable: false, label: "Detection <br/>Version", width: "10%" },
  { id: "analysis_version", numeric: false, sortable: false, label: "Script <br/>Version", width: "10%" },
  { id: "scoring_metrics_version", numeric: false, sortable: false, label: "Metrics <br/>Version", width: "10%" },
  { id: "total_score", numeric: true, sortable: false, label: "Total <br/>Score" },
  { id: "date", numeric: false, sortable: false, label: "Date", width: date_width },
  { id: "enabled", numeric: false, sortable: false, label: "Active" },
  { id: "Status", numeric: false, sortable: false, label: "Status", width: status_width },
];

export function getAdminNameLetters(firstName, lastName) {
  if (!firstName || !lastName) return "-";
  return firstName[0] + lastName[0];
}

export function formatToTwoDecimals(num) {
  return Math.floor(num * 100) / 100 + (num % 1 === 0 ? ".00" : "");
}

export function getScoreStatusClass(rawScore) {
  if (rawScore < 25) {
    return "bad";
  } else if (rawScore >= 25 && rawScore < 50) {
    return "average";
  } else if (rawScore >= 50 && rawScore < 75) {
    return "good";
  } else {
    return "very-good";
  }
}

export function formatStringClass(input) {
  if (!input) return "";
  return input
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export function formatStringId(input) {
  if (!input) return "";
  const firstPart = input.slice(0, 5);
  const lastPart = input.slice(-5);
  return `${firstPart}...${lastPart}`;
}

export function formatDateTime(input) {
  if (!input) return "-";
  return moment.utc(input).format("DD-MM-YYYY HH:mm");
}

export function toSnakeCase(text) {
  return text
    .trim()
    .toLowerCase()
    .replace(/[\s\-]+/g, "_");
}

export const imageBaseUrl = "https://dev-admin-restapi.aim-football.com/images";

export function formatEmail(input) {
  if (typeof input !== "string") return "";
  let atIndex = input.indexOf("@");
  return atIndex !== -1 ? input.slice(0, atIndex) : input;
}

export function formatBeforeUnderscore(str) {
  if (typeof str !== "string") return "";
  let underscoreIndex = str.indexOf("_");
  return underscoreIndex !== -1 ? str.slice(0, underscoreIndex) : str;
}

export const scoringMetricsTypes = [{ label: "Score Version-1", value: 1 }];

export const game_types = [
  { label: "PASSING_RECEIVING_TURNING", value: "PASSING_RECEIVING_TURNING" },
  { label: "DIAMOND_DRIBBLING_DRILL", value: "DIAMOND_DRIBBLING_DRILL" },
  { label: "MOVEMENT_TO_RECEIVE_ON_BACK_FOOT_AND_PASS", value: "MOVEMENT_TO_RECEIVE_ON_BACK_FOOT_AND_PASS" },
  { label: "FIRST_TOUCH_DRILL", value: "FIRST_TOUCH_DRILL" },
  { label: "SAQ_SPEED", value: "SAQ_SPEED" },
];

export const statusOptions = [
  { label: "PROCESSED", value: "PROCESSED" },
  { label: "UPLOADED", value: "UPLOADED" },
  { label: "FAILED", value: "FAILED" },
  { label: "PROCESSING", value: "PROCESSING" },
];
// function formatString(input) {
//   if (!input) return;
//   const formatted = input.replace(/_/g, " ");
//   return formatted.charAt(0).toUpperCase() + formatted.slice(1);
// }

// const highlightText = (text) => {
//   if (!searchValue) return text;
//   const regex = new RegExp(`(${searchValue})`, "gi");
//   return text.replace(regex, '<span class="highlight">$1</span>');
// };

export function formatDrillScoreReport(jsonData) {
  if (!jsonData) return "";
  const date = formatDateTime(jsonData?.generated_at);
  let report = `=== SOCCER DRILL SCORES REPORT ===\nGenerated on: ${date}\n\n`;
  report += `Version: ${jsonData?.analysis_metadata?.version}\n\n`;

  // Summary
  report += `=== DRILL SUMMARY ===\n\n`;
  report += `Total Activities: ${jsonData?.analysis_summary?.total_activities}\n`;
  report += `- Dribbling Activities: ${jsonData?.analysis_summary?.dribbling_activities_count}\n`;
  report += `- Passing Activities: ${jsonData?.analysis_summary?.passing_activities_count}\n`;
  report += `Total Phases: ${jsonData?.analysis_summary?.total_phases}\n\n`;

  // Performance Metrics
  report += `=== PERFORMANCE METRICS ===\n\n`;
  report += `Total score: ${jsonData?.performance_metrics?.total_score}\n\n`;

  function processObject(obj, indent = "") {
    for (const [key, value] of Object.entries(obj)) {
      if (key === "_id") continue;
      if (typeof value === "object" && value !== null) {
        report += `${indent}${key.charAt(0).toUpperCase() + key.slice(1)}:\n`;
        processObject(value, indent + "  ");
      } else {
        report += `${indent}${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}\n`;
      }
    }
  }

  for (const [key, value] of Object.entries(jsonData?.performance_metrics)) {
    if (typeof value === "object" && value !== null && key !== "_id") {
      report += `${key.charAt(0).toUpperCase() + key.slice(1)} Performance:\n\n`;
      processObject(value, "  ");
      report += `\n\n`;
    }
  }

  report += `=== AREAS ===\n\n`;

  for (const [key, value] of Object.entries(jsonData?.areas)) {
    if (typeof value === "object" && value !== null && key !== "_id") {
      report += `${key.charAt(0).toUpperCase() + key.slice(1)}:\n\n`;
      processObject(value, "  ");
      report += `\n\n`;
    }
  }

  report += `=== ACTIVITIES TIMELINE ===\n\n`;

  for (const [key, value] of Object.entries(jsonData?.activity_timeline)) {
    if (typeof value === "object" && value !== null && key !== "_id") {
      report += `${key.charAt(0).toUpperCase() + key.slice(1)}:\n\n`;
      processObject(value, "  ");
      report += `\n\n`;
    }
  }

  report += `=== SUMMARY ===\n\n`;

  report += `Total weighted_ score: ${jsonData?.summary?.total_weighted_score}\n`;
  report += `Area count: ${jsonData?.summary?.area_count}\n`;
  report += `Category count: ${jsonData?.summary?.category_count}\n`;
  report += `Game type: ${jsonData?.gameType}\n\n`;

  return report;
}
