import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary, { accordionSummaryClasses } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

const Accordion = styled((props) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "6px",
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]: {
    transform: "rotate(90deg)",
  },
  [`& .${accordionSummaryClasses.content}`]: {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function RolesAccordion({ title, value }) {
  const disabled = Object.keys(value).length === 1 && value.hasOwnProperty("_id");

  const [expanded, setExpanded] = React.useState("");
  const [expandedChild, setExpandedChild] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChangeChild = (panel) => (event, newExpanded) => {
    setExpandedChild(newExpanded ? panel : false);
  };

  return (
    <Accordion
      disabled={disabled}
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}>
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header">
        {title}
      </AccordionSummary>
      <AccordionDetails>
        {value.Description && (
          <p>
            Description: <strong>{value.Description}</strong>
          </p>
        )}
        {value["Overall Weighting"] && (
          <p>
            Overall Weighting: <strong>{value["Overall Weighting"]}</strong>
          </p>
        )}
        {value.Scoring && (
          <div className="metrics__scoring">
            <p className="metrics__scoring-title">
              <strong>Scoring:</strong>
            </p>
            {Object.entries(value.Scoring).map(([key, val]) => (
              <Accordion
                key={key}
                expanded={expandedChild === `panel-child-${key}`}
                onChange={handleChangeChild(`panel-child-${key}`)}>
                <AccordionSummary
                  aria-controls={`panel-child-${key}d-content`}
                  id={`panel-child-${key}d-header`}>
                  {key}
                </AccordionSummary>
                <AccordionDetails>
                  {typeof val === "object" && val !== null ? (
                    Object.entries(val).map(([innerKey, innerVal]) => (
                      <div
                        key={innerKey}
                        className="metrics__scoring-item">
                        {innerKey === "Values" && Array.isArray(innerVal) ? (
                          <div>
                            Values:{" "}
                            <strong>
                              <code>{JSON.stringify(innerVal, null, 0)}</code>
                            </strong>
                          </div>
                        ) : (
                          <p>
                            {innerKey}: <strong>{innerVal}</strong>
                          </p>
                        )}
                      </div>
                    ))
                  ) : (
                    <p>
                      {key}: <strong>{val}</strong>
                    </p>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
