import { useForm, Controller } from "react-hook-form";
import { useState, useCallback, useEffect } from "react";
import { DialogActions, DialogContent } from "@mui/material";
import { Button, SelectPicker } from "rsuite";
import AuthFormGroup from "../auth/AuthFormGroup";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../store/snackbarSlice";
import { instance } from "../../routes/axios";

export default function SetAnalysisVersionsDefualtForm({ close, fetchData }) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [analysisVersionsTypes, setAnalysisVersionsTypes] = useState([]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { analysis_version: "" },
    mode: "onChange",
  });

  const fetchAnalysisVersions = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await instance.get("/drill-analysis/versions", {});
      if (res?.data?.data) {
        const formattedData = res?.data?.data?.map((x) => {
          return {
            label: `${x?.title} (${x?.version}) ${x?.isDefault ? "Default" : ""}`,
            value: x?._id,
          };
        });
        setAnalysisVersionsTypes(formattedData);
      } else {
        setAnalysisVersionsTypes([]);
      }
    } catch (err) {
      dispatch(showSnackbar({ message: "Something went wrong", severity: "error" }));
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchAnalysisVersions();
  }, [fetchAnalysisVersions]);

  async function onSubmit(data) {
    setIsLoading(true);
    try {
      await instance.put(`/drill-analysis/version/${data?.analysis_version}`,{
        params: { _id: data?.analysis_version },
      });
      await fetchData();
      close();
      dispatch(
        showSnackbar({
          message: "Default Version has been successfully updated",
          severity: "success",
        })
      );
    } catch (err) {
      dispatch(
        showSnackbar({
          message: err?.response?.data?.message || "Something went wrong",
          severity: "error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <DialogContent
        dividers
        className="c-dialog__content">
        <div
          className="form prompts__form"
          id="scroll-dialog-description">
          <div className="form__fields">
            <AuthFormGroup
              label="Analysis Version"
              error={errors.analysis_version}>
              <Controller
                name="analysis_version"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <SelectPicker
                    size="lg"
                    id={field?.name}
                    value={field?.value}
                    data={analysisVersionsTypes}
                    searchable={false}
                    onChange={field.onChange}
                    placeholder="Select Analysis Version"
                  />
                )}
              />
            </AuthFormGroup>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          appearance="ghost"
          onClick={close}
          className="outlined-btn">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
          appearance="primary"
          className="outlined-btn black-btn min-width">
          Set Defualt
        </Button>
      </DialogActions>
    </>
  );
}
