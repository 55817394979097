import { useForm } from "react-hook-form";
import { useState } from "react";
import { InputAdornment, IconButton, DialogContent, DialogActions } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button } from "rsuite";
import AuthFormGroup from "@components/auth/AuthFormGroup";
import { useSelector } from "react-redux";
import { useChangePassword } from "@api/useChangePassword";
import { Controller } from "react-hook-form";
import { Input, InputGroup } from "rsuite";

export default function ChangePasswordDialog({ close }) {
  const [visible, setVisible] = useState(false);
  const admin = useSelector((state) => state?.admin?.data?.data);

  const { changePassword, isLoading } = useChangePassword();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    await changePassword(data, admin?.email, () => {
      reset();
      close();
    });
  };

  const handleChange = () => {
    setVisible((prev) => !prev);
  };

  return (
    <>
      <DialogContent
        className="c-dialog__content"
        dividers>
        <div
          id="scroll-dialog-description"
          onSubmit={handleSubmit(onSubmit)}
          className="form">
          <div className="form__fields">
            <AuthFormGroup
              label="Old Password"
              error={errors.oldPassword}>
              <Controller
                name="oldPassword"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Input
                    type="password"
                    value={field.value}
                    onChange={field.onChange}
                    placeholder="Enter old password"
                  />
                )}
              />
            </AuthFormGroup>
            <AuthFormGroup
              label="New Password"
              error={errors.newPassword}>
              <Controller
                name="newPassword"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <InputGroup inside>
                    <Input
                      type={visible ? "text" : "password"}
                      value={field.value}
                      onChange={field.onChange}
                      placeholder="Enter new password"
                    />
                    <InputGroup.Button onClick={handleChange}>{visible ? <Visibility /> : <VisibilityOff />}</InputGroup.Button>
                  </InputGroup>
                )}
              />
            </AuthFormGroup>
            <AuthFormGroup
              label="Confirm New Password"
              error={errors.confirmNewPassword}>
              <Controller
                name="confirmNewPassword"
                control={control}
                rules={{
                  required: true,
                  validate: (val) => {
                    if (watch("newPassword") !== val) {
                      return "* Your passwords do no match";
                    }
                  },
                }}
                render={({ field }) => (
                  <Input
                    type="password"
                    value={field.value}
                    onChange={field.onChange}
                    placeholder="Confirm new password"
                  />
                )}
              />
            </AuthFormGroup>
          </div>
          <div className="c-dialog__btns"></div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="c-dialog__btns">
          <Button
            appearance="ghost"
            onClick={close}
            className="outlined-btn">
            Cancel
          </Button>
          <Button
            appearance="primary"
            onClick={handleSubmit(onSubmit)}
            loading={isLoading}
            className="outlined-btn black-btn">
            Change Password
          </Button>
        </div>
      </DialogActions>
    </>
  );
}
